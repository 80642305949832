const KeyIcon = () => {
    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24" id="Layer_1" data-name="Layer 1">
            <defs>
                <style>{`.cls-1{fill:none;}.cls-2{clip - path:url(#clip-path);}`}</style>
                <clipPath id="clip-path">
                    <rect className="cls-1" x="0.15" y="-0.12" width="24" height="24"/>
                </clipPath>
            </defs>
            <title>key</title>
            <g className="cls-2">
                <path
                    d="M7.29,21.77a6.11,6.11,0,1,1,6.11-6.1A6.11,6.11,0,0,1,7.29,21.77Zm0-10.71a4.61,4.61,0,1,0,4.61,4.61A4.61,4.61,0,0,0,7.29,11.06Z"/>

                <path
                    d="M11.08,12.63a.71.71,0,0,1-.53-.22.74.74,0,0,1,0-1.06l8.9-8.9a.75.75,0,0,1,1.06,0l2.08,2.07a.75.75,0,0,1,0,1.06.75.75,0,0,1-1.06,0L20,4l-8.37,8.37A.74.74,0,0,1,11.08,12.63Z"/>

                <path
                    d="M19,8.13a.74.74,0,0,1-.53-.22L16.77,6.19a.75.75,0,1,1,1.06-1.06l1.72,1.72a.74.74,0,0,1,0,1.06A.73.73,0,0,1,19,8.13Z"/>
            </g>
        </svg>
    )
};
export default KeyIcon;