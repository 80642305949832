import React from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = (props) => {
    const {dailyCounts = []} = props;
    
    var dataSeries = {
        name: "Count",
        data: (dailyCounts).map(item => ({
            x: new Date(item.date).getTime(),
            y: item.count
        }))
    };

    var options = {
        chart: {
            type: 'line',
            // width: "100%",
            height: 350,
            // toolbar: {
            //     autoSelected: "zoom"
            // },
            events: {
                mounted: (chart) => {
                    chart.windowResizeHandler();
                }
            }
        },
        sparkline: {
            enabled: true
        },
        stroke: {
            curve: 'straight',
            width: 3,
        },
        dataLabels: {
            enabled: false
        },
        series: [dataSeries],
        markers: {
            size: 0
        },
        title: {
            // text: "Date Wise Download Chart",
            // align: "left"
        },
        xaxis: {
            type: "datetime",
            labels: {
                datetimeUTC: false
            }
        },
        tooltip: {
            x: {
                formatter: function (value) {
                    return new Date(value).toLocaleDateString();
                }
            }
        },
        yaxis: {
            title: {
                text: "Count"
            }
        }
    };

    return (
        <>
            <div id="chart">
                <ReactApexChart
                    options={options}
                    series={options.series}
                    type="line"
                    height="350"
                />
            </div>
        </>
    );
};

export default LineChart;
