import React, {useRef} from 'react'
import {Select} from 'antd';
import {downArrowIcon} from "../../assets/icons";
import "./SelectMultiple.scss"


const SelectMultiple = props => {
    const {
        label,
        name,
        value,
        onChange,
        className,
        placeholder,
        labelIndex,
        selectClass,
        disabled,
        defaultValue,
        options,
        error,
        autoClose,
        required,
        errorMsg,
        labelClass
    } = props;
    const [blur, setBlur] = React.useState(false);
    const selectRef = useRef();
    // const filteredOptions = options.filter(o => !value.includes(o));
    function errorStatus() {
        return required ? error ? true : (blur && !value) : false
    }

    return (
        <div className={`SelectMultiple mt-20 ${className}`}>
            <div className="form-group">
                {label && <label className={`labelClass ${labelClass} margin-left-5`}>{label}{required &&
                    <span className="color-red">*</span>}</label>}
                <Select
                    mode="multiple"
                    allowClear
                    style={{width: '100%'}}
                    className={`${errorStatus() && 'error'} ${selectClass}`}
                    placeholder={placeholder || "Please select"}
                    showArrow
                    ref={selectRef}
                    suffixIcon={<img src={downArrowIcon} alt={""}/>}
                    value={value}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    onChange={(e, e2) => {
                        onChange({target: {name, value: e}});
                        autoClose && selectRef.current.blur();
                    }}
                    onBlur={() => setBlur(true)}
                >
                    {options?.map((option, index) => {
                        return (
                            <Select.Option key={index}
                                           value={option.id || option.value || option._id}>{option.label || option.name || [option[labelIndex]]}</Select.Option>
                        )
                    })}
                </Select>
                {errorStatus() &&
                    <span className="margin-left-5 error-label">{`${errorMsg || name} is required`}</span>}
            </div>
        </div>
    )
};

export default SelectMultiple
