import {call, takeLatest} from "redux-saga/effects";
//
import {request} from "../../../utils/fetch";
// constants
import {AuthConstants} from "./authConstants";
// APIS
import {config} from "../../../utils/config";
import * as API from "../../../utils/apiConsts";

function* fetchPlatformList(action) {
    yield call(
        request({
            type: AuthConstants.LOGIN,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.userBase}${API.LoginUrl}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

export default function* rootSaga() {
    yield takeLatest(AuthConstants.LOGIN, fetchPlatformList);
}
