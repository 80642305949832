import {handleActions} from "redux-actions";
import {initialNotificationState} from "./initialNotificationState";
import {NotificationConstants} from "./notificationConstants";
import {
    requestFail, requestPending, requestSuccess
} from "../../../utils/fetch";

export const notificationReducer = handleActions({
    [requestSuccess(NotificationConstants.FETCH_NOTIFICATION_LIST)]: (state, action) => ({
        ...state,
        fetchNotificationLoading: false,
        fetchNotificationFailure: false,
        fetchNotificationSuccess: true,
        notificationList: (action.payload?.data || [])
    }), [requestPending(NotificationConstants.FETCH_NOTIFICATION_LIST)]: state => ({
        ...state,
        fetchNotificationLoading: true,
        fetchNotificationFailure: false,
        fetchNotificationSuccess: false,
        notificationList: []
    }), [requestFail(NotificationConstants.FETCH_NOTIFICATION_LIST)]: state => ({
        ...state,
        fetchNotificationLoading: false,
        fetchNotificationFailure: true,
        fetchNotificationSuccess: false,
        notificationList: []
    }),
    [requestSuccess(NotificationConstants.REMOVE_ALL_NOTIFICATION_LIST)]: (state, action) => ({
        ...state,
        deleteNotificationLoading: false,
        deleteNotificationFailure: false,
        deleteNotificationSuccess: true
    }), [requestPending(NotificationConstants.REMOVE_ALL_NOTIFICATION_LIST)]: state => ({
        ...state,
        deleteNotificationLoading: true,
        deleteNotificationFailure: false,
        deleteNotificationSuccess: false,
    }), [requestFail(NotificationConstants.REMOVE_ALL_NOTIFICATION_LIST)]: state => ({
        ...state,
        deleteNotificationLoading: false,
        deleteNotificationFailure: true,
        deleteNotificationSuccess: false,
    }),
    [requestSuccess(NotificationConstants.READ_ALL_NOTIFICATION_LIST)]: (state, action) => ({
        ...state,
        readAllNotificationLoading: false,
        readAllNotificationFailure: false,
        readAllNotificationSuccess: true
    }), [requestPending(NotificationConstants.READ_ALL_NOTIFICATION_LIST)]: state => ({
        ...state,
        readAllNotificationLoading: true,
        readAllNotificationFailure: false,
        readAllNotificationSuccess: false
    }), [requestFail(NotificationConstants.READ_ALL_NOTIFICATION_LIST)]: state => ({
        ...state,
        readAllNotificationLoading: false,
        readAllNotificationFailure: true,
        readAllNotificationSuccess: false
    }),
    [requestSuccess(NotificationConstants.CHECK_NOTIFICATION)]: (state, action) => ({
        ...state,
        checkAllNotificationLoading: false,
        checkAllNotificationFailure: false,
        checkAllNotificationSuccess: true,
        notificationUpdateData: (action.payload?.data || {})
    }), [requestPending(NotificationConstants.CHECK_NOTIFICATION)]: state => ({
        ...state,
        checkAllNotificationLoading: true,
        checkAllNotificationFailure: false,
        checkAllNotificationSuccess: false,
        notificationUpdateData: {}
    }), [requestFail(NotificationConstants.CHECK_NOTIFICATION)]: state => ({
        ...state,
        checkAllNotificationLoading: false,
        checkAllNotificationFailure: true,
        checkAllNotificationSuccess: false,
        notificationUpdateData: {}
    }),
    [requestSuccess(NotificationConstants.CHECK_ACCOUNT_NOTIFICATION)]: (state, action) => ({
        ...state,
        checkAccountNotificationLoading: false,
        checkAccountNotificationFailure: false,
        checkAccountNotificationSuccess: true,
        accountNotificationUpdateData: (action.payload?.data || {})
    }), [requestPending(NotificationConstants.CHECK_ACCOUNT_NOTIFICATION)]: state => ({
        ...state,
        checkAccountNotificationLoading: true,
        checkAccountNotificationFailure: false,
        checkAccountNotificationSuccess: false,
        accountNotificationUpdateData: {}
    }), [requestFail(NotificationConstants.CHECK_ACCOUNT_NOTIFICATION)]: state => ({
        ...state,
        checkAccountNotificationLoading: false,
        checkAccountNotificationFailure: true,
        checkAccountNotificationSuccess: false,
        accountNotificationUpdateData: {}
    }),
}, initialNotificationState());
