import {call, takeLeading,takeEvery} from "redux-saga/effects";
//
import {request} from "../../../utils/fetch";

//Constants
import {NotificationConstants} from "./notificationConstants";

// APIS
import {config} from "../../../utils/config";
import * as API from "../../../utils/apiConsts";

function* fetchNotificationList(action) {
    yield call(
        request({
            type: NotificationConstants.FETCH_NOTIFICATION_LIST,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.NotificationBase}?type=${action?.payload?.data?.type}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* removeAllNotificationList(action) {
    yield call(
        request({
            type: NotificationConstants.REMOVE_ALL_NOTIFICATION_LIST,
            method: "DELETE",
            baseURL: config.BASE_URL,
            url: `${API.NotificationBase}${API.NotificationAllRemoveRead}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* readAllNotificationList(action) {
    yield call(
        request({
            type: NotificationConstants.READ_ALL_NOTIFICATION_LIST,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.NotificationBase}${API.NotificationAllRemoveRead}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* checkNotificationList(action) {
    yield call(
        request({
            type: NotificationConstants.CHECK_NOTIFICATION,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.NotificationBase}${API.CheckNotification}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* checkAccountNotificationList(action) {
    yield call(
        request({
            type: NotificationConstants.CHECK_ACCOUNT_NOTIFICATION,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.NotificationBase}${API.CheckAccountNotificationList}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

export default function* rootSaga() {
    yield takeEvery(NotificationConstants.FETCH_NOTIFICATION_LIST, fetchNotificationList);
    yield takeLeading(NotificationConstants.REMOVE_ALL_NOTIFICATION_LIST, removeAllNotificationList);
    yield takeLeading(NotificationConstants.READ_ALL_NOTIFICATION_LIST, readAllNotificationList);
    yield takeLeading(NotificationConstants.CHECK_NOTIFICATION, checkNotificationList);
    yield takeLeading(NotificationConstants.CHECK_ACCOUNT_NOTIFICATION, checkAccountNotificationList);
}
