import React, {useEffect, useState} from "react";
import {DatePicker, Space} from 'antd';
import dayjs from 'dayjs';

const {RangePicker} = DatePicker;

const rangePresets = [
    {
        label: 'Today',
        value: [dayjs(), dayjs()],
    },
    {
        label: 'Yesterday',
        value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
    },
    {
        label: 'Last 7 Days',
        value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
        label: 'Last 30 Days',
        value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
        label: 'Last 90 Days',
        value: [dayjs().subtract(90, 'day'), dayjs()],
    },
];

const CustomRangePicker = (props) => {
    const {setFilter, filter, clearValue, setClearValue} = props;
    const [selectedRange, setSelectedRange] = useState([
        filter?.startDate ? dayjs(filter?.startDate) : null,
        filter?.endDate ? dayjs(filter?.endDate) : null,
    ]);

    useEffect(() => {
        if (clearValue) {
            setSelectedRange([]);
            setClearValue(!clearValue);
        }
    }, [clearValue]);

    const onRangeChange = (dates) => {
        setSelectedRange(dates);
        if (dates?.length === 2) {
            const startDate = dates[0]?.format("YYYY/MM/DD");
            const endDate = dates[1]?.format("YYYY/MM/DD");
            setFilter((prevState) => ({
                ...prevState,
                startDate,
                endDate,
            }));
        } else {
            setFilter((prevState) => ({
                ...prevState,
                startDate: null,
                endDate: null,
            }));
        }
    };

    return (
        <Space direction="vertical" size={12}>
            <RangePicker
                value={selectedRange}
                presets={rangePresets}
                format="YYYY/MM/DD"
                onChange={onRangeChange}
            />
        </Space>
    );
};

export default React.memo(CustomRangePicker);
