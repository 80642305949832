import {handleActions} from "redux-actions";
import {initialAuthState} from "./initialAuthState";
import {AuthConstants} from "./authConstants";
import {
    requestFail, requestPending, requestSuccess
} from "../../../utils/fetch";
import {setIntoStorage} from "../../../utils/common";

export const authReducer = handleActions({
    [requestSuccess(AuthConstants.LOGIN)]: (
        state,
        action
    ) => {
        let access_token = "";
        if (action.payload) {
            const data = action.payload;
            access_token = data.token;
            setIntoStorage({...data});
        }
        return ({
            ...state,
            access_token: access_token || "",
            userDetail: action.payload,
            loginLoading: false,
            loginFailure: false,
            loginLoaded: true
        })
    },
    [requestPending(AuthConstants.LOGIN)]: state => ({
        ...state,
        loginLoading: true,
        loginFailure: false,
        loginLoaded: false
    }),
    [requestFail(AuthConstants.LOGIN)]: state => ({
        ...state,
        access_token: "",
        userDetail: {},
        loginLoading: false,
        loginFailure: true,
        loginLoaded: false
    }),
    [AuthConstants.LOGOUT]: (
        state
    ) => {
        localStorage.clear();
        return ({
            ...state,
            userDetail:{},
            access_token: "",
        })
    },
}, initialAuthState());
