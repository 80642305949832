import {call, takeLatest, takeLeading} from "redux-saga/effects";
//
import {request} from "../../../utils/fetch";
//
import {AllAppsConstants} from "./allAppsConstants";

// APIS
import {config} from "../../../utils/config";
import * as API from "../../../utils/apiConsts";

function* fetchAppList(action) {
    yield call(
        request({
            type: AllAppsConstants.FETCH_APP_LIST,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.ApplicationBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* fetchAppListByPagination(action) {
    yield call(
        request({
            type: AllAppsConstants.FETCH_APP_LIST_BY_PAGE,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.ApplicationBase}${API.GetAPPbyPage}page=${action.payload.data?.page}&limit=${action.payload.data?.pageSize}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* checkPackageName(action) {
    yield call(
        request({
            type: AllAppsConstants.CHECK_PACKAGE_NAME,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.ApplicationBase}${API.GetAPPbyPage}packageName=${action.payload.data?.packageName}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* createApps(action) {
    yield call(
        request({
            type: AllAppsConstants.CREATE_APP,
            method: "POST",
            url: `${API.ApplicationBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
            baseURL: config.BASE_URL,
        }),
        action
    );
}

function* fetchAppListById(action) {
    yield call(
        request({
            type: AllAppsConstants.FETCH_APP_LIST_BY_ID,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.ApplicationBase}/${action.payload.id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* editApp(action) {
    yield call(
        request({
            type: AllAppsConstants.EDIT_APP,
            method: "PUT",
            baseURL: config.BASE_URL,
            url: `${API.ApplicationBase}/${action.payload.data._id}?toAsign=${action.payload.data.toAsign || false}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* editAppStatus(action) {
    yield call(
        request({
            type: AllAppsConstants.EDIT_APP_STATUS,
            method: "PUT",
            baseURL: config.BASE_URL,
            url: `${API.ApplicationBase}${API.ChangeAppStatus}${action.payload.data._id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* deleteRestoreApplication(action) {
    yield call(
        request({
            type: AllAppsConstants.DELETE_RESTORE_TRASH_APP,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.ApplicationBase}/${action?.payload?.data?.id}/${action?.payload?.data?.isRestore}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* deleteApp(action) {
    yield call(
        request({
            type: AllAppsConstants.DELETE_APP,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.ApplicationBase}/${action.payload?.data}`,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* fetchAppByAccount(action) {
    yield call(
        request({
            type: AllAppsConstants.FETCH_APP_LIST_BY_ACCOUNT,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.ApplicationBase}${API.GetAppByAccount}${action.payload?.data}`,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* fetchParameterByAppAccount(action) {
    yield call(
        request({
            type: AllAppsConstants.FETCH_PARAMETER_BY_APP_ACCOUNT,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.ApplicationBase}${API.CopyParameter}`,
            success: action.payload.onSuccess,
            data: action.payload.data,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* getTrashListApplication(action) {
    yield call(
        request({
            type: AllAppsConstants.FETCH_APP_FROM_TRASH,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.appTrashBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* doMultipleOperation(action) {
    yield call(
        request({
            type: AllAppsConstants.DO_MUTLTI_TASKING,
            method: "DELETE",
            baseURL: config.BASE_URL,
            url: `${API.ApplicationBase}${API.MultiOperations}${action?.payload?.data?.isDelete}`,
            success: action.payload.onSuccess,
            data: action.payload.data,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* getChatListData(action) {
    yield call(
        request({
            type: AllAppsConstants.FETCH_CHART_DATA,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.ChartData}${action?.payload?.id}`,
            success: action.payload.onSuccess,
            data: action.payload.data,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* getAllChartList(action) {
    yield call(
        request({
            type: AllAppsConstants.FETCH_ALL_APP_CHART,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.ChartData}`,
            success: action.payload.onSuccess,
            data: action.payload.data,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* getLocationListData(action) {
    yield call(
        request({
            type: AllAppsConstants.FETCH_LOCATION_DATA,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.ChartData}${API.ChartLocationData}${action?.payload?.id}`,
            success: action.payload.onSuccess,
            data: action.payload.data,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* doMultipleOperationForTrash(action) {
    yield call(
        request({
            type: AllAppsConstants.DO_MULTI_TASKING_TRASH,
            method: "PUT",
            baseURL: config.BASE_URL,
            url: `${API.ApplicationBase}${API.MultiOperationTrash}${action?.payload?.data?.isDelete}`,
            success: action.payload.onSuccess,
            data: action.payload.data,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* getAllChartListFilter(action) {
    yield call(
        request({
            type: AllAppsConstants.FETCH_ALL_APP_CHART_FILTER,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.ChartData}${API.ChartDataFilter}`,
            success: action.payload.onSuccess,
            data: action.payload.data,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* getAllChartListApp(action) {
    yield call(
        request({
            type: AllAppsConstants.FETCH_ALL_CHART_APP,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.ChartData}${API.ChartDataFilter}${API.ChartDataByApp}`,
            success: action.payload.onSuccess,
            data: action.payload.data,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* getAllChartListAccount(action) {
    yield call(
        request({
            type: AllAppsConstants.FETCH_ALL_CHART_ACCOUNT,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.ChartData}${API.ChartDataFilter}${API.ChartDataByAccount}`,
            success: action.payload.onSuccess,
            data: action.payload.data,
            fail: action.payload.onFail,
        }),
        action
    );
}

export default function* rootSaga() {
    yield takeLeading(AllAppsConstants.FETCH_APP_LIST, fetchAppList);
    yield takeLeading(AllAppsConstants.FETCH_APP_LIST_BY_PAGE, fetchAppListByPagination);
    yield takeLeading(AllAppsConstants.FETCH_APP_FROM_TRASH, getTrashListApplication);
    yield takeLatest(AllAppsConstants.CREATE_APP, createApps);
    yield takeLatest(AllAppsConstants.EDIT_APP, editApp);
    yield takeLeading(AllAppsConstants.FETCH_APP_LIST_BY_ID, fetchAppListById);
    yield takeLatest(AllAppsConstants.DELETE_APP, deleteApp);
    yield takeLatest(AllAppsConstants.DELETE_RESTORE_TRASH_APP, deleteRestoreApplication);
    yield takeLatest(AllAppsConstants.FETCH_APP_LIST_BY_ACCOUNT, fetchAppByAccount);
    yield takeLatest(AllAppsConstants.FETCH_PARAMETER_BY_APP_ACCOUNT, fetchParameterByAppAccount);
    yield takeLatest(AllAppsConstants.EDIT_APP_STATUS, editAppStatus);
    yield takeLatest(AllAppsConstants.DO_MUTLTI_TASKING, doMultipleOperation);
    yield takeLatest(AllAppsConstants.FETCH_CHART_DATA, getChatListData);
    yield takeLatest(AllAppsConstants.FETCH_ALL_APP_CHART, getAllChartList);
    yield takeLatest(AllAppsConstants.FETCH_LOCATION_DATA, getLocationListData);
    yield takeLatest(AllAppsConstants.DO_MULTI_TASKING_TRASH, doMultipleOperationForTrash);
    yield takeLatest(AllAppsConstants.FETCH_ALL_APP_CHART_FILTER, getAllChartListFilter);
    yield takeLatest(AllAppsConstants.FETCH_ALL_CHART_ACCOUNT, getAllChartListAccount);
    yield takeLatest(AllAppsConstants.FETCH_ALL_CHART_APP, getAllChartListApp);
    yield takeLatest(AllAppsConstants.CHECK_PACKAGE_NAME, checkPackageName);
}
