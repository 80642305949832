import {handleActions} from "redux-actions";
import {initialPlatformState} from "./initialPlatformState";
import {PlatformConstants} from "./platformConstants";
import {
    requestFail, requestPending, requestSuccess
} from "../../../utils/fetch";

export const platformReducer = handleActions({
    [requestSuccess(PlatformConstants.FETCH_PLATFORM_LIST)]: (state, action) => ({
        ...state,
        fetchPlatformLoading: false,
        fetchPlatformFailure: false,
        fetchPlatformSuccess: true,
        platformDataList: (action.payload?.data || [])
    }), [requestPending(PlatformConstants.FETCH_PLATFORM_LIST)]: state => ({
        ...state,
        fetchPlatformLoading: true,
        fetchPlatformFailure: false,
        fetchPlatformSuccess: false,
        platformDataList: []
    }), [requestFail(PlatformConstants.FETCH_PLATFORM_LIST)]: state => ({
        ...state,
        fetchPlatformLoading: false,
        fetchPlatformFailure: true,
        fetchPlatformSuccess: false,
        platformDataList: []
    }),
    [requestSuccess(PlatformConstants.FETCH_PLATFORM_BY_ID)]: (state, action) => ({
        ...state,
        fetchPlatformByIdLoading: false,
        fetchPlatformByIdFailure: false,
        fetchPlatformByIdSuccess: true,
        platformDataById: action?.payload?.data || {}
    }), [requestPending(PlatformConstants.FETCH_PLATFORM_BY_ID)]: state => ({
        ...state,
        fetchPlatformByIdLoading: true,
        fetchPlatformByIdFailure: false,
        fetchPlatformByIdSuccess: false,
        platformDataById: {}
    }), [requestFail(PlatformConstants.FETCH_PLATFORM_BY_ID)]: state => ({
        ...state,
        fetchPlatformByIdLoading: false,
        fetchPlatformByIdFailure: true,
        fetchPlatformByIdSuccess: false,
        platformDataById: {}
    }),
    [requestSuccess(PlatformConstants.CREATE_PLATFORM)]: (state, action) => ({
        ...state,
        createPlatformLoading: false,
        createPlatformFailure: false,
        createPlatformSuccess: true,
    }), [requestPending(PlatformConstants.CREATE_PLATFORM)]: state => ({
        ...state,
        createPlatformLoading: true,
        createPlatformFailure: false,
        createPlatformSuccess: false,
    }), [requestFail(PlatformConstants.CREATE_PLATFORM)]: state => ({
        ...state,
        createPlatformLoading: false,
        createPlatformFailure: true,
        createPlatformSuccess: false,
    }),
    [requestSuccess(PlatformConstants.EDIT_PLATFORM)]: (state, action) => ({
        ...state,
        editPlatformLoading: false,
        editPlatformFailure: false,
        editPlatformSuccess: true,
    }), [requestPending(PlatformConstants.EDIT_PLATFORM)]: state => ({
        ...state,
        editPlatformLoading: true,
        editPlatformFailure: false,
        editPlatformSuccess: false,
    }), [requestFail(PlatformConstants.EDIT_PLATFORM)]: state => ({
        ...state,
        editPlatformLoading: false,
        editPlatformFailure: true,
        editPlatformSuccess: false,
    }),
    [requestSuccess(PlatformConstants.PLATFORM_TRASH_LIST)]: (state, action) => ({
        ...state,
        fetchPlatformTrashLoading: false,
        fetchPlatformTrashFailure: false,
        fetchPlatformTrashSuccess: true,
        platformTrashDataList: (action.payload?.data || [])
    }), [requestPending(PlatformConstants.PLATFORM_TRASH_LIST)]: state => ({
        ...state,
        fetchPlatformTrashLoading: true,
        fetchPlatformTrashFailure: false,
        fetchPlatformTrashSuccess: false,
        platformTrashDataList: []
    }), [requestFail(PlatformConstants.PLATFORM_TRASH_LIST)]: state => ({
        ...state,
        fetchPlatformTrashLoading: false,
        fetchPlatformTrashFailure: true,
        fetchPlatformTrashSuccess: false,
        platformTrashDataList: []
    }),
    [requestSuccess(PlatformConstants.ADD_PLATFORM_TO_TRASH)]: (state, action) => ({
        ...state,
        addPlatformToTrashLoading: false,
        addPlatformToTrashFailure: false,
        addPlatformToTrashSuccess: true,
    }), [requestPending(PlatformConstants.ADD_PLATFORM_TO_TRASH)]: state => ({
        ...state,
        addPlatformToTrashLoading: true,
        addPlatformToTrashFailure: false,
        addPlatformToTrashSuccess: false,
    }), [requestFail(PlatformConstants.ADD_PLATFORM_TO_TRASH)]: state => ({
        ...state,
        addPlatformToTrashLoading: false,
        addPlatformToTrashFailure: true,
        addPlatformToTrashSuccess: false,
    }),
    [requestSuccess(PlatformConstants.DELETE_RESTORE_TRASH_PLATFORM)]: (state, action) => ({
        ...state,
        addRestoreTrashPlatformLoading: false,
        addRestoreTrashPlatformFailure: false,
        addRestoreTrashPlatformSuccess: true,
    }), [requestPending(PlatformConstants.DELETE_RESTORE_TRASH_PLATFORM)]: state => ({
        ...state,
        addRestoreTrashPlatformLoading: true,
        addRestoreTrashPlatformFailure: false,
        addRestoreTrashPlatformSuccess: false,
    }), [requestFail(PlatformConstants.DELETE_RESTORE_TRASH_PLATFORM)]: state => ({
        ...state,
        addRestoreTrashPlatformLoading: false,
        addRestoreTrashPlatformFailure: true,
        addRestoreTrashPlatformSuccess: false,
    }),
}, initialPlatformState());
