import {call, takeLeading} from "redux-saga/effects";
//
import {request} from "../../../utils/fetch";

//Constants
import {UserConstants} from "./userConstants";

// APIS
import * as API from "../../../utils/apiConsts";
import {config} from "../../../utils/config";

function* fetchUserList(action) {
    yield call(
        request({
            type: UserConstants.FETCH_USERS_LIST,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.userBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* updatingUser(action) {
    yield call(
        request({
            type: UserConstants.UPDATE_USER_DATA,
            method: "PATCH",
            baseURL: config.BASE_URL,
            url: `${API.userBase}${API.userUpdateUrl}${action.payload.data.id}?changeImage=${action.payload.data.changeImage}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}
export default function* rootSaga() {
    yield takeLeading(UserConstants.FETCH_USERS_LIST, fetchUserList);
    yield takeLeading(UserConstants.UPDATE_USER_DATA, updatingUser);
}
