const PlayStore = (props) => {
    return (
        <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 300">
            <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="64.102" x2="252.841" y1="71.183" y2="71.183">
                <stop offset=".077" stopColor="#54b06a"/>
                <stop offset=".374" stopColor="#65b362"/>
            </linearGradient>
            <linearGradient id="b" gradientUnits="userSpaceOnUse" x1="63.563" x2="253.273" y1="213.19" y2="213.19">
                <stop offset=".121" stopColor="#df2253"/>
                <stop offset=".451" stopColor="#e63d49"/>
            </linearGradient>
            <linearGradient id="c" gradientUnits="userSpaceOnUse" x1="201.586" x2="314.648" y1="141.998" y2="141.998">
                <stop offset=".077" stopColor="#fcc40d"/>
                <stop offset=".286" stopColor="#fdce0f"/>
                <stop offset=".683" stopColor="#fee40e"/>
                <stop offset=".769" stopColor="#fee90f"/>
            </linearGradient>
            <linearGradient id="d" gradientUnits="userSpaceOnUse" x1="130.505" x2="130.505" y1="3.546" y2="279.75">
                <stop offset=".198" stopColor="#48bce8"/>
                <stop offset=".593" stopColor="#61c4e2"/>
            </linearGradient>
            <path d="M248.362 96.074L83.983 3.24c-8.145-4.616-15.392-4.124-19.881.379l137.8 138.743 26.136-12.412c13.581-7.654 33.889-26.189 20.324-33.876z"
                  fill="url(#a)"/>
            <path d="M201.567 142.018l33.75 21.188c13.563 7.687 25.532 15.19 12.032 24.94L84.225 281.081c-8.761 4.937-16.268 4.147-20.663-1.332l43.573-52.287z"
                  fill="url(#b)"/>
            <path d="M304.485 127.715L247.817 95.83l-46.231 46.216 45.761 46.119 57.105-32.557c1.697-.957 3.184-1.99 4.457-3.081s2.336-2.239 3.186-3.426c.213-.296.412-.596.598-.896 4.276-6.927 1.542-14.965-8.208-20.49z"
                  fill="url(#c)"/>
            <path d="M64.191 3.546c-2.989 2.993-4.763 7.753-4.756 13.983l.271 249.041c.006 5.535 1.417 10.163 3.836 13.179l138.033-137.743z"
                  fill="url(#d)"/>
            <path d="M59.353 17.61C59.335 2.019 70.419-4.447 83.983 3.24l220.411 124.516c13.564 7.687 13.549 20.239-.033 27.893L84.35 281.081c-13.583 7.653-24.709 1.16-24.726-14.431z"
                  fill="none"/>
            <path d="M311.081 132.986c4.525 7.004 2.194 16.157-8.337 21.912L82.734 280.33c-8.466 4.771-15.778 2.926-20.24-2.072 4.176 6.594 11.958 8.303 21.809 2.782l220.01-125.432c11.037-5.906 13.083-15.258 6.768-22.622z"
                  fill="#ccc"/>
        </svg>

    )
};
export default PlayStore;

