import React from "react";
import { Chart } from "react-google-charts";

function GeoChart(props) {
    const {locationData = [],newTitleData} = props;

    const data = [
        ["Country", "Popularity"],
        ...locationData
    ];

    return (
        <Chart
            chartEvents={[
                {
                    eventName: "select",
                    callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = newTitleData ? newTitleData[selection[0].row + 1] : data[selection[0].row + 1];
                        // console.log(region,"Selected : " + selection[0].row);
                    }
                }
            ]}
            chartType="GeoChart"
            width="100%"
            height="400px"
            data={newTitleData ? newTitleData : data}
        />
    );
}

export default GeoChart;
