export const initialNotificationState = () => ({
    fetchNotificationLoading: false,
    fetchNotificationFailure: false,
    fetchNotificationSuccess: false,
    notificationList: [],
    readAllNotificationLoading: false,
    readAllNotificationFailure: false,
    readAllNotificationSuccess: false,
    deleteNotificationLoading: false,
    deleteNotificationFailure: false,
    deleteNotificationSuccess: false,
    checkAllNotificationLoading: false,
    checkAllNotificationFailure: false,
    checkAllNotificationSuccess: false,
    notificationUpdateData: {},
    checkAccountNotificationLoading: false,
    checkAccountNotificationFailure: false,
    checkAccountNotificationSuccess: false,
    accountNotificationUpdateData: [],
    getAccountNotificationLoading: false,
    getAccountNotificationFailure: false,
    getAccountNotificationSuccess: false,
    accountNotificationList: []
});
