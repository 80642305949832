import {useContext, useState} from "react";
import {InboxOutlined} from '@ant-design/icons';
import {message, Typography, Upload} from 'antd';

import {errorMessage, successMessage} from "../../utils/common";
import {AppViewPlacementContext} from "../../context";

const {Dragger} = Upload;

const FileUpload = ({description}) => {
    const {isFromModal, uploadZipFiles} = useContext(AppViewPlacementContext);
    const [errorMsg, setErrorMsg] = useState('');
    const props = {
        beforeUpload: (file) => {
            const isZip = file.type === 'application/zip' || file.name.endsWith('.zip')
            if (!isZip) {
                setErrorMsg(`${file.name} is not a zip file`);
                errorMessage(`${file.name} is not a zip file`);
                return false;
            }
            setErrorMsg('');
            return isZip || Upload.LIST_IGNORE;
        },
        name: 'file',
        multiple: false,
        action: 'https://api.cloudinary.com/v1_1/collections135/upload',
        data: {
            upload_preset: 'c5doim9f',
            cloud_name: 'collections135'
        },
        onChange(info) {
            const {status} = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                (uploadZipFiles && isFromModal) && uploadZipFiles(info.file.response, isFromModal, description)
                setErrorMsg('');
                successMessage(`${info.file.name} file uploaded successfully.`)
            } else if (status === 'error') {
                setErrorMsg(`${info.file.name} file upload failed.`);
                errorMessage(`${info.file.name} file upload failed.`)
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
        showUploadList: false,
        iconRender: () => null
    };

    return (
        <>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                    banned files.
                </p>
            </Dragger>
            <Typography.Text type="danger">{errorMsg}</Typography.Text>
        </>
    )
};
export default FileUpload;
