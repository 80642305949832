export const initialOtherKeysAccessState = () => ({
    fetchOtherKeysAccessLoading: false,
    fetchOtherKeysAccessFailure: false,
    fetchOtherKeysAccessSuccess: false,
    otherKeysAccessDataList: [],
    createOtherKeysAccessLoading: false,
    createOtherKeysAccessFailure: false,
    createOtherKeysAccessSuccess: false,
    editOtherKeysAccessLoading: false,
    editOtherKeysAccessFailure: false,
    editOtherKeysAccessSuccess: false,
    fetchByIdOtherKeysAccessLoading: false,
    fetchByIdOtherKeysAccessFailure: false,
    fetchByIdOtherKeysAccessSuccess: false,
    otherKeysAccessDataById: {},
    deleteOtherKeysAccessLoading: false,
    deleteOtherKeysAccessFailure: false,
    deleteOtherKeysAccessSuccess: false,
    assignKeyToAppLoading: false,
    assignKeyToAppFailure: false,
    assignKeyToAppSuccess: false,
    deleteAllTheKeysLoading: false,
    deleteAllTheKeysFailure: false,
    deleteAllTheKeysSuccess: false,
});
