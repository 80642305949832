import {call, takeLeading} from "redux-saga/effects";
//
import {request} from "../../../utils/fetch";

//Constants
import {OtherKeysAccessConstants} from "./otherKeysAccessConstants";

// APIS
import {config} from "../../../utils/config";
import * as API from "../../../utils/apiConsts";

function* fetchOtherKeysDataList(action) {
    yield call(
        request({
            type: OtherKeysAccessConstants.FETCH_OTHER_KEYS_LIST,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.AssignKeyBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* deleteOtherKeys(action) {
    yield call(
        request({
            type: OtherKeysAccessConstants.DELETE_OTHER_KEYS,
            method: "DELETE",
            baseURL: config.BASE_URL,
            url: `${API.AssignKeyBase}/${action?.payload?.data?._id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* assignKeysToApp(action) {
    yield call(
        request({
            type: OtherKeysAccessConstants.ASSIGN_KEYS_TO_APP,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.AssignKeyBase}/${action?.payload?.data?._id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* deleteAllKeysList(action) {
    yield call(
        request({
            type: OtherKeysAccessConstants.DELETE_ALL_KEYS,
            method: "PUT",
            baseURL: config.BASE_URL,
            url: `${API.AssignKeyBase}${API.DeleteAllUrl}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

export default function* rootSaga() {
    yield takeLeading(OtherKeysAccessConstants.FETCH_OTHER_KEYS_LIST, fetchOtherKeysDataList);
    yield takeLeading(OtherKeysAccessConstants.DELETE_OTHER_KEYS, deleteOtherKeys);
    yield takeLeading(OtherKeysAccessConstants.ASSIGN_KEYS_TO_APP, assignKeysToApp);
    yield takeLeading(OtherKeysAccessConstants.DELETE_ALL_KEYS, deleteAllKeysList);
}
