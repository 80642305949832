export const initialAddExtraState = () => ({
    extraFieldLoading: false,
    extraFieldFailure: false,
    extraFieldSuccess: false,
    extraFieldList: [],
    createFieldLoading: false,
    createFieldFailure: false,
    createFieldSuccess: false,
    editFieldLoading: false,
    editFieldFailure: false,
    editFieldSuccess: false,
    deleteFieldLoading: false,
    deleteFieldFailure: false,
    deleteFieldSuccess: false,
    extraAllFieldLoading: false,
    extraAllFieldFailure: false,
    extraAllFieldSuccess: false,
    extraAllFieldList: [],
    editMultiFieldLoading: false,
    editMultiFieldFailure: false,
    editMultiFieldSuccess: false,
});