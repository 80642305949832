import React, {useEffect, useState} from "react";
import {
    Route,
    Routes,
    Navigate, useLocation
} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ProSidebarProvider} from "react-pro-sidebar";

import {getAuthToken, getFromStorage} from "./utils/common";
import TheSidebar from "./containers/TheSidebar/TheSidebar";
import {AuthProvider} from "./context";
import {Loading} from "./reusable";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./SASS/app.scss";
import {checkAllNotification} from "./redux/modules/Notification/notificationActions";

const Dashboard = React.lazy(() => import('./views/Dashboard'));
const Home = React.lazy(() => import('./views/Home'));
const AllApps = React.lazy(() => import("./views/AllApps"));
const AllAppsEdit = React.lazy(() => import("./views/AllApps/Edit"));
const AllAppsView = React.lazy(() => import("./views/AllApps/View"));
const Platform = React.lazy(() => import("./views/Platform"));
const Notification = React.lazy(() => import("./views/Notification"));
const Login = React.lazy(() => import("./views/Login"));
const Profile = React.lazy(() => import("./views/Profile"));
const Category = React.lazy(() => import("./views/Category"));
const UserList = React.lazy(() => import("./views/UserList"));
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const TestKeys = React.lazy(() => import('./views/TestKeys'));
const AppExtraField = React.lazy(() => import('./views/AppExtraField'));
const ConsoleList = React.lazy(() => import('./views/ConsoleList'));
const ConsoleCreateEdit = React.lazy(() => import('./views/ConsoleList/Create'));
const DownloadAppsChart = React.lazy(() => import('./views/DownloadAppsChart'));
const OuterKeysAccess = React.lazy(() => import('./views/Dashboard/components/OuterKeysAccess'));
const ViewAccountAppList = React.lazy(() => import('./views/Dashboard/components/ViewAccountAppList'));

const PublicRoutes = ({children}) => {
    const token = useSelector(x => x?.authorization)?.access_token || getAuthToken();
    return token ? <Navigate replace to="/"/> : children
};

function PrivateRoute({name, children}) {
    const token = useSelector(x => x?.authorization)?.access_token || getFromStorage('token');
    if (!token) {
        return <Navigate to="/login"/>
    }
    return <TheLayout name={name}>{children}</TheLayout>;
}

global.defaultImg = 'https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg';

const App = () => {

    const token = useSelector(x => x?.authorization)?.access_token || getAuthToken();
    const [callNotificationApi, setCallNotificationApi] = useState({});
    const location = useLocation();

    const dispatch = useDispatch();

    useEffect(() => {
        token && checkNotificationUpdate();
    }, [location]);

    function checkNotificationUpdate() {
        const onSuccess = response => {
            const {data} = response;
            setCallNotificationApi(data?.callNotification || {});
        };
        const onFail = err => {
        };
        dispatch(checkAllNotification({onSuccess, onFail}));
    }

    return (
        <>
            <React.Suspense fallback={<Loading/>}>
                <AuthProvider callNotificationApi={callNotificationApi}
                              setCallNotificationApi={setCallNotificationApi}>
                    <ProSidebarProvider>
                        <div className={`${token ? "flex-row min-vh-100 h-100 d-flex" : ""} h-100`}>
                            {token && <div
                                style={{
                                    display: "flex",
                                }}
                            >
                                <TheSidebar/>
                            </div>}
                            <Routes>
                                <Route
                                    path="/account"
                                    exact='true'
                                    element={
                                        <PrivateRoute name='Play store Account'>
                                            <Dashboard/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/"
                                    exact='true'
                                    element={
                                        <PrivateRoute name='Play store Account'>
                                            <Home/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/account/view"
                                    exact='true'
                                    element={
                                        <PrivateRoute name='View Application List'>
                                            <ViewAccountAppList/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact='true'
                                    path="/all-apps"
                                    element={
                                        <PrivateRoute name='All Application List'>
                                            <AllApps/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact='true'
                                    path="/all-console"
                                    element={
                                        <PrivateRoute name='All Console List'>
                                            <ConsoleList/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact='true'
                                    path="/all-console/create"
                                    element={
                                        <PrivateRoute name='Create Console'>
                                            <ConsoleCreateEdit/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact='true'
                                    path="/all-console/edit/:id"
                                    element={
                                        <PrivateRoute name='Edit Console'>
                                            <ConsoleCreateEdit/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact='true'
                                    path="/all-apps/edit"
                                    element={
                                        <PrivateRoute name='Edit Application'>
                                            <AllAppsEdit/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact='true'
                                    path="/all-apps/view"
                                    element={
                                        <PrivateRoute name='View Application'>
                                            <AllAppsView/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact='true'
                                    path="/notification"
                                    element={
                                        <PrivateRoute name='Notification'>
                                            <Notification/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact='true'
                                    path="/platform"
                                    element={
                                        <PrivateRoute name='Platform'>
                                            <Platform/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact='true'
                                    path="/profile"
                                    element={
                                        <PrivateRoute name='Profile'>
                                            <Profile/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact='true'
                                    path="/category"
                                    element={
                                        <PrivateRoute name="Category's">
                                            <Category/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact='true'
                                    path="/user-list"
                                    element={
                                        <PrivateRoute name="User List">
                                            <UserList/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact='true'
                                    path="/extra-fields"
                                    element={
                                        <PrivateRoute name="App Extra Fields">
                                            <AppExtraField/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact='true'
                                    path="/all-report"
                                    element={
                                        <PrivateRoute name="User List">
                                            <DownloadAppsChart/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact='true'
                                    path="/test-keys"
                                    element={
                                        <PrivateRoute name="Test Keys">
                                            <TestKeys/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    exact='true'
                                    path="/outer-keys"
                                    element={
                                        <PrivateRoute name="Outer Keys">
                                            <OuterKeysAccess/>
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/login"
                                    exact='true'
                                    element={
                                        <PublicRoutes>
                                            <Login/>
                                        </PublicRoutes>
                                    }
                                />
                                <Route
                                    path="*"
                                    element={
                                        <Navigate to="/"/>
                                    }
                                />
                            </Routes>
                        </div>
                    </ProSidebarProvider>
                </AuthProvider>
            </React.Suspense>
        </>
    )
};

export default App;
