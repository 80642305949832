// *** Application ***
export const ApplicationBase = '/Application'
export const GetAPPbyPage = '/get?';
export const ChangeAppStatus = '/change-status/';
export const GetAppByAccount = '/getAppByAccount/';
export const CopyParameter = '/copyParameter';
export const MultiOperations = '/multi-operations/';
export const ChartData = '/chartData/';
export const ChartDataFilter = 'chart-filter/';
export const ChartDataByApp = 'by-application';
export const ChartDataByAccount = 'by-account';
export const ChartLocationData = 'location-chart/';
export const MultiOperationTrash = '/multi-operations/trash-app/';


// *** TrashList ***
export const appTrashBase = '/applicationTrashlist';
export const accountTrashBase = '/accountTrashlist';
export const platformTrashBase = '/platform-trashlist';
export const accountMultiTrashRestoreBase = '/trash-app/';


// *** Auth AND USERS ***
export const userBase = '/users';
export const userUpdateUrl = '/profile/';
export const LoginUrl = '/login';

// *** Category ***
export const CategoryBase = '/category';

// *** Dashboard ***
export const AccountBase = '/Account';
export const APPAccountBase = '/getAppdata/';
export const AssignKeyBase = '/assignKey';
export const DeleteAllUrl = '/delete-all-keys';

// *** Platform ***
export const PlatformBase = '/Platform';


// *** TestSSHKey ***
export const TestSSHKeyBase = '/TestSSHKey';

// *** Notification ***
export const NotificationBase = '/notification';
export const NotificationAllRemoveRead = '/notification-all';
export const CheckNotification = '/check-notification';
export const CheckAccountNotificationList = '/check-acc-notification';

// *** ConsoleList ***
export const ConsoleBase = '/console';
export const ConsolePasswordBase = '/consolePassword';

// *** Extra Field ***
export const ExtraFieldBase = '/extra-field';