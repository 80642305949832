import {call, takeLatest, takeLeading} from "redux-saga/effects";
//
import {request} from "../../../utils/fetch";
//Constants
import {CategoryConstants} from "./categoryConstants";

// APIS
import {config} from "../../../utils/config";
import * as API from "../../../utils/apiConsts";

function* fetchCategoryList(action) {
    yield call(
        request({
            type: CategoryConstants.FETCH_CATEGORY,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.CategoryBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* createCategory(action) {
    yield call(
        request({
            type: CategoryConstants.CREATE_CATEGORY,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.CategoryBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* editCategory(action) {
    yield call(
        request({
            type: CategoryConstants.EDIT_CATEGORY,
            method: "PUT",
            baseURL: config.BASE_URL,
            url: `${API.CategoryBase}/${action?.payload?.data?._id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* deleteCategory(action) {
    yield call(
        request({
            type: CategoryConstants.DELETE_CATEGORY,
            method: "DELETE",
            baseURL: config.BASE_URL,
            url: `${API.CategoryBase}/${action?.payload?._id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

export default function* rootSaga() {
    yield takeLatest(CategoryConstants.FETCH_CATEGORY, fetchCategoryList);
    yield takeLatest(CategoryConstants.CREATE_CATEGORY, createCategory);
    yield takeLatest(CategoryConstants.EDIT_CATEGORY, editCategory);
    yield takeLatest(CategoryConstants.DELETE_CATEGORY, deleteCategory);
}
