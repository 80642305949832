export const initialPlatformState = () => ({
    fetchPlatformLoading: false,
    fetchPlatformFailure: false,
    fetchPlatformSuccess: false,
    platformDataList: [],
    createPlatformLoading: false,
    createPlatformFailure: false,
    createPlatformSuccess: false,
    fetchPlatformByIdLoading: false,
    fetchPlatformByIdFailure: false,
    fetchPlatformByIdSuccess: false,
    platformDataById: {},
    editPlatformLoading: false,
    editPlatformFailure: false,
    editPlatformSuccess: false,
    fetchPlatformTrashLoading: false,
    fetchPlatformTrashFailure: false,
    fetchPlatformTrashSuccess: false,
    platformTrashDataList: [],
    addPlatformToTrashLoading: false,
    addPlatformToTrashFailure: false,
    addPlatformToTrashSuccess: false,
    addRestoreTrashPlatformLoading: false,
    addRestoreTrashPlatformFailure: false,
    addRestoreTrashPlatformSuccess: false,
});
