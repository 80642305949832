import {handleActions} from "redux-actions";
import {initialAuthState} from "./initialCategoryState";
import {CategoryConstants} from "./categoryConstants";
import {
    requestFail, requestPending, requestSuccess
} from "../../../utils/fetch";

export const categoryReducer = handleActions({
    [requestSuccess(CategoryConstants.FETCH_CATEGORY)]: (
        state,
        action
    ) => ({
        ...state,
        categoryList: action?.payload?.data || [],
        fetchCategoryLoading: false,
        fetchCategoryFailure: false,
        fetchCategoryLoaded: true
    }),
    [requestPending(CategoryConstants.FETCH_CATEGORY)]: state => ({
        ...state,
        fetchCategoryLoading: true,
        fetchCategoryFailure: false,
        fetchCategoryLoaded: false,
        categoryList: []
    }),
    [requestFail(CategoryConstants.FETCH_CATEGORY)]: state => ({
        ...state,
        categoryList: [],
        fetchCategoryLoading: false,
        fetchCategoryFailure: true,
        fetchCategoryLoaded: false
    }),
    [requestSuccess(CategoryConstants.CREATE_CATEGORY)]: (
        state,
        action
    ) => ({
        ...state,
        createCategoryLoading: false,
        createCategoryFailure: false,
        createCategoryLoaded: true
    }),
    [requestPending(CategoryConstants.CREATE_CATEGORY)]: state => ({
        ...state,
        createCategoryLoading: true,
        createCategoryFailure: false,
        createCategoryLoaded: false,
    }),
    [requestFail(CategoryConstants.CREATE_CATEGORY)]: state => ({
        ...state,
        createCategoryLoading: false,
        createCategoryFailure: true,
        createCategoryLoaded: false
    }),
    [requestSuccess(CategoryConstants.EDIT_CATEGORY)]: (
        state,
        action
    ) => ({
        ...state,
        editCategoryLoading: false,
        editCategoryFailure: false,
        editCategoryLoaded: true
    }),
    [requestPending(CategoryConstants.EDIT_CATEGORY)]: state => ({
        ...state,
        editCategoryLoading: true,
        editCategoryFailure: false,
        editCategoryLoaded: false,
    }),
    [requestFail(CategoryConstants.EDIT_CATEGORY)]: state => ({
        ...state,
        editCategoryLoading: false,
        editCategoryFailure: true,
        editCategoryLoaded: false
    }),
    [requestSuccess(CategoryConstants.DELETE_CATEGORY)]: (
        state,
        action
    ) => ({
        ...state,
        deleteCategoryLoading: false,
        deleteCategoryFailure: false,
        deleteCategoryLoaded: true
    }),
    [requestPending(CategoryConstants.DELETE_CATEGORY)]: state => ({
        ...state,
        deleteCategoryLoading: true,
        deleteCategoryFailure: false,
        deleteCategoryLoaded: false,
    }),
    [requestFail(CategoryConstants.DELETE_CATEGORY)]: state => ({
        ...state,
        deleteCategoryLoading: false,
        deleteCategoryFailure: true,
        deleteCategoryLoaded: false
    }),
}, initialAuthState());
