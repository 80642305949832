import {Radio as AntdRadio} from "antd";
import React from "react";

const Radio = (props) => {
    const {options, onChange, size, value, simpleRadio, name, label, afterLabelClass, afterLabel, radioLabel, className, labelClass} = props;
    return (<>
            {label && <label className={`labelClass ${labelClass}`}>{label}</label>}
            {simpleRadio ? <AntdRadio size={size} className={className}>{radioLabel}</AntdRadio> :
                <AntdRadio.Group className={className} size={size} name={name} options={options} onChange={onChange}
                                 value={value}/>}
            {afterLabel && <label className={`labelClass ${afterLabelClass}`}>{afterLabel}</label>}
        </>
    )
};
export default Radio;
