import {handleActions} from "redux-actions";
import {initialTestKeysState} from "./initialTestKeysState";
import {TestKeysConstants} from "./testKeysConstants";
import {
    requestFail, requestPending, requestSuccess
} from "../../../utils/fetch";

export const testKeysReducer = handleActions({
    [requestSuccess(TestKeysConstants.FETCH_TEST_KEYS_LIST)]: (state, action) => ({
        ...state,
        fetchTestKeysLoading: false,
        fetchTestKeysFailure: false,
        fetchTestKeysSuccess: true,
        testKeysDataList: (action.payload?.data || [])
    }), [requestPending(TestKeysConstants.FETCH_TEST_KEYS_LIST)]: state => ({
        ...state,
        fetchTestKeysLoading: true,
        fetchTestKeysFailure: false,
        fetchTestKeysSuccess: false,
        testKeysDataList: []
    }), [requestFail(TestKeysConstants.FETCH_TEST_KEYS_LIST)]: state => ({
        ...state,
        fetchTestKeysLoading: false,
        fetchTestKeysFailure: true,
        fetchTestKeysSuccess: false,
        testKeysDataList: []
    }),
    [requestSuccess(TestKeysConstants.CREATE_TEST_KEYS_LIST)]: (state, action) => ({
        ...state,
        createTestKeysLoading: false,
        createTestKeysFailure: false,
        createTestKeysSuccess: true,
    }), [requestPending(TestKeysConstants.CREATE_TEST_KEYS_LIST)]: state => ({
        ...state,
        createTestKeysLoading: true,
        createTestKeysFailure: false,
        createTestKeysSuccess: false,
    }), [requestFail(TestKeysConstants.CREATE_TEST_KEYS_LIST)]: state => ({
        ...state,
        createTestKeysLoading: false,
        createTestKeysFailure: true,
        createTestKeysSuccess: false,
    }),
    [requestSuccess(TestKeysConstants.EDIT_TEST_KEYS_LIST)]: (state, action) => ({
        ...state,
        editTestKeysLoading: false,
        editTestKeysFailure: false,
        editTestKeysSuccess: true,
    }), [requestPending(TestKeysConstants.EDIT_TEST_KEYS_LIST)]: state => ({
        ...state,
        editTestKeysLoading: true,
        editTestKeysFailure: false,
        editTestKeysSuccess: false,
    }), [requestFail(TestKeysConstants.EDIT_TEST_KEYS_LIST)]: state => ({
        ...state,
        editTestKeysLoading: false,
        editTestKeysFailure: true,
        editTestKeysSuccess: false,
    }),
    [requestSuccess(TestKeysConstants.FETCH_BY_ID_TEST_KEYS_LIST)]: (state, action) => ({
        ...state,
        fetchByIdTestKeysLoading: false,
        fetchByIdTestKeysFailure: false,
        fetchByIdTestKeysSuccess: true,
        testKeysDataById: action?.payload?.data || {}
    }), [requestPending(TestKeysConstants.FETCH_BY_ID_TEST_KEYS_LIST)]: state => ({
        ...state,
        fetchByIdTestKeysLoading: true,
        fetchByIdTestKeysFailure: false,
        fetchByIdTestKeysSuccess: false,
        testKeysDataById: {}
    }), [requestFail(TestKeysConstants.FETCH_BY_ID_TEST_KEYS_LIST)]: state => ({
        ...state,
        fetchByIdTestKeysLoading: false,
        fetchByIdTestKeysFailure: true,
        fetchByIdTestKeysSuccess: false,
        testKeysDataById: {}
    }),
    [requestSuccess(TestKeysConstants.DELETE_TEST_KEYS)]: (state, action) => ({
        ...state,
        deleteTestKeysLoading: false,
        deleteTestKeysFailure: false,
        deleteTestKeysSuccess: true,
    }), [requestPending(TestKeysConstants.DELETE_TEST_KEYS)]: state => ({
        ...state,
        deleteTestKeysLoading: true,
        deleteTestKeysFailure: false,
        deleteTestKeysSuccess: false,
    }), [requestFail(TestKeysConstants.DELETE_TEST_KEYS)]: state => ({
        ...state,
        deleteTestKeysLoading: false,
        deleteTestKeysFailure: true,
        deleteTestKeysSuccess: false,
    }),
}, initialTestKeysState());
