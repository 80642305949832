import React from 'react'
import {rightArrowBlue} from "../../assets/icons";
import "./IdLabel.scss"

const IdLabel = props => {
    const {
        label,
        error,
        required,
        value,
        m0,
        mb0,
        label2,
        labelClassName,
        value2,
        link,
        linkClass,
        label2className,
        className,
        onClick,
        labelOnClick
    } = props;
    return (
        <div className={`idLabel mt-20 ${className}`}>
            <div className="row">
                {label && <div onClick={labelOnClick} className={`col${label2 ? "-6" : "-12"} ${labelClassName}`}>
                    <label className={`labelClass ${mb0 && 'mb-0'}`}>{label}{required &&
                        <span className="color-red">*</span>}</label>
                </div>}
                {label2 && <div className={`col-6 ${label2className}`}>
                    <label className="labelClass">{label2}</label>
                </div>}
                {value && <div className={`col${label2 ? "-6" : "-12"}`}>
                    {!!link ?
                        <span onClick={onClick} className="common-link">
                            {value}
                            <img src={rightArrowBlue} alt={"arrow"}/>
                        </span>
                        : <span onClick={() => onClick && onClick()}
                                className={`inputClassLabel ${linkClass} overflow-hidden d-flex ${!!onClick ? "cursor-pointer" : ""}`}>{value}</span>}
                </div>}
                {label2 && <div className="col-6">
                    <span className="inputClassLabel d-flex">{value2}</span>
                </div>}
            </div>
        </div>
    )
};


export default IdLabel
