import {handleActions} from "redux-actions";
import {initialConsoleState} from "./initialConsoleState";
import {ConsoleConstants} from "./consoleConstants";
import {
    requestFail, requestPending, requestSuccess
} from "../../../utils/fetch";

export const consoleReducer = handleActions({
    [requestSuccess(ConsoleConstants.FETCH_CONSOLE_LIST)]: (state, action) => ({
        ...state,
        fetchConsoleLoading: false,
        fetchConsoleFailure: false,
        fetchConsoleSuccess: true,
        consoleList: action.payload?.data || []
    }), [requestPending(ConsoleConstants.FETCH_CONSOLE_LIST)]: state => ({
        ...state,
        fetchConsoleLoading: true,
        fetchConsoleFailure: false,
        fetchConsoleSuccess: false,
        consoleList: []
    }), [requestFail(ConsoleConstants.FETCH_CONSOLE_LIST)]: state => ({
        ...state,
        fetchConsoleLoading: false,
        fetchConsoleFailure: true,
        fetchConsoleSuccess: false,
        consoleList: []
    }),
    [requestSuccess(ConsoleConstants.CREATE_CONSOLE)]: (state) => ({
        ...state,
        createConsoleLoading: false,
        createConsoleFailure: false,
        createConsoleSuccess: true,
    }), [requestPending(ConsoleConstants.CREATE_CONSOLE)]: state => ({
        ...state,
        createConsoleLoading: true,
        createConsoleFailure: false,
        createConsoleSuccess: false,
    }), [requestFail(ConsoleConstants.CREATE_CONSOLE)]: state => ({
        ...state,
        createConsoleLoading: false,
        createConsoleFailure: true,
        createConsoleSuccess: false,
    }),
    [requestSuccess(ConsoleConstants.FETCH_CONSOLE_BY_ID)]: (state, action) => ({
        ...state,
        fetchConsoleByIDLoading: false,
        fetchConsoleByIDFailure: false,
        fetchConsoleByIDSuccess: true,
        consoleByID: action.payload?.data || {}
    }), [requestPending(ConsoleConstants.FETCH_CONSOLE_BY_ID)]: state => ({
        ...state,
        fetchConsoleByIDLoading: true,
        fetchConsoleByIDFailure: false,
        fetchConsoleByIDSuccess: false,
        consoleByID: {}
    }), [requestFail(ConsoleConstants.FETCH_CONSOLE_BY_ID)]: state => ({
        ...state,
        fetchConsoleByIDLoading: false,
        fetchConsoleByIDFailure: true,
        fetchConsoleByIDSuccess: false,
        consoleByID: {}
    }),
    [requestSuccess(ConsoleConstants.EDIT_CONSOLE)]: (state) => ({
        ...state,
        editConsoleLoading: false,
        editConsoleFailure: false,
        editConsoleSuccess: true,
    }), [requestPending(ConsoleConstants.EDIT_CONSOLE)]: state => ({
        ...state,
        editConsoleLoading: true,
        editConsoleFailure: false,
        editConsoleSuccess: false,
    }), [requestFail(ConsoleConstants.EDIT_CONSOLE)]: state => ({
        ...state,
        editConsoleLoading: false,
        editConsoleFailure: true,
        editConsoleSuccess: false,
    }),
    [requestSuccess(ConsoleConstants.DELETE_CONSOLE)]: (state) => ({
        ...state,
        deleteConsoleLoading: false,
        deleteConsoleFailure: false,
        deleteConsoleSuccess: true,
    }), [requestPending(ConsoleConstants.DELETE_CONSOLE)]: (state) => ({
        ...state,
        deleteConsoleLoading: true,
        deleteConsoleFailure: false,
        deleteConsoleSuccess: false
    })
    , [requestFail(ConsoleConstants.DELETE_CONSOLE)]: state => ({
        ...state,
        deleteConsoleLoading: false,
        deleteConsoleFailure: true,
        deleteConsoleSuccess: false,
    }),
    [requestSuccess(ConsoleConstants.CHECK_CONSOLE_BY_ID)]: (state, action) => ({
        ...state,
        checkConsoleByIDLoading: false,
        checkConsoleByIDFailure: false,
        checkConsoleByIDSuccess: true,
        consoleCheckByID: action.payload?.data || {}
    }), [requestPending(ConsoleConstants.CHECK_CONSOLE_BY_ID)]: state => ({
        ...state,
        checkConsoleByIDLoading: true,
        checkConsoleByIDFailure: false,
        checkConsoleByIDSuccess: false,
        consoleCheckByID: {}
    }), [requestFail(ConsoleConstants.CHECK_CONSOLE_BY_ID)]: state => ({
        ...state,
        checkConsoleByIDLoading: false,
        checkConsoleByIDFailure: true,
        checkConsoleByIDSuccess: false,
        consoleCheckByID: {}
    }),
    [requestSuccess(ConsoleConstants.LOGIN_TO_CONSOLE)]: (state, action) => ({
        ...state,
        consoleLoginLoading: false,
        consoleLoginFailure: false,
        consoleLoginSuccess: true,
        consoleLogin: action?.payload?.isDone ? !action?.payload?.data?.isNewUser : false
    }), [requestPending(ConsoleConstants.LOGIN_TO_CONSOLE)]: state => ({
        ...state,
        consoleLoginLoading: true,
        consoleLoginFailure: false,
        consoleLoginSuccess: false,
        consoleLogin: false
    }), [requestFail(ConsoleConstants.LOGIN_TO_CONSOLE)]: state => ({
        ...state,
        consoleLoginLoading: false,
        consoleLoginFailure: true,
        consoleLoginSuccess: false,
        consoleLogin: false
    }),
    [requestSuccess(ConsoleConstants.UPDATE_CONSOLE_PASSWORD)]: (state, action) => ({
        ...state,
        consoleResetLoading: false,
        consoleResetFailure: false,
        consoleResetSuccess: true,
    }), [requestPending(ConsoleConstants.UPDATE_CONSOLE_PASSWORD)]: state => ({
        ...state,
        consoleResetLoading: true,
        consoleResetFailure: false,
        consoleResetSuccess: false,
    }), [requestFail(ConsoleConstants.UPDATE_CONSOLE_PASSWORD)]: state => ({
        ...state,
        consoleResetLoading: false,
        consoleResetFailure: true,
        consoleResetSuccess: false,
    }),
}, initialConsoleState());
