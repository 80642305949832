export const initialTestKeysState = () => ({
    fetchTestKeysLoading: false,
    fetchTestKeysFailure: false,
    fetchTestKeysSuccess: false,
    testKeysDataList: [],
    createTestKeysLoading: false,
    createTestKeysFailure: false,
    createTestKeysSuccess: false,
    editTestKeysLoading: false,
    editTestKeysFailure: false,
    editTestKeysSuccess: false,
    fetchByIdTestKeysLoading: false,
    fetchByIdTestKeysFailure: false,
    fetchByIdTestKeysSuccess: false,
    testKeysDataById: {},
    deleteTestKeysLoading: false,
    deleteTestKeysFailure: false,
    deleteTestKeysSuccess: false,
});
