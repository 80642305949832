export const initialUserState = () => ({
    fetchUserLoading: false,
    fetchUserFailure: false,
    fetchUserSuccess: false,
    userDataList: [],
    updateUserLoading: false,
    updateUserFailure: false,
    updateUserSuccess: false,
    userData: {}
});
