const envType = process.env.REACT_APP_ENV || "development";

const types = {
    development: {
        BASE_URL: process.env.REACT_APP_BASE_URL,
        SOCKET_BASE: process.env.REACT_APP_BASE_URL,
    },
    production: {
        BASE_URL: process.env.REACT_APP_BASE_URL,
        SOCKET_BASE: process.env.REACT_APP_BASE_URL,
    }
};

export const config = types[envType];
