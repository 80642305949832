export const initialAllAppsState = () => ({
    fetchAllAppsLoading: false,
    fetchAllAppsFailure: false,
    fetchAllAppsSuccess: false,
    appsList: [],
    createAppLoading: false,
    createAppFailure: false,
    createAppSuccess: false,
    fetchAllAppsByIDLoading: false,
    fetchAllAppsByIDFailure: false,
    fetchAllAppsByIDSuccess: true,
    appByID: {},
    editAppLoading: false,
    editAppFailure: false,
    editAppSuccess: false,
    deleteAppLoading: false,
    deleteAppFailure: false,
    deleteAppSuccess: false,
    fetchAllAppsByPageLoading: false,
    fetchAllAppsByPageFailure: false,
    fetchAllAppsByPageSuccess: false,
    appsListByPage: {},
    fetchAppsByAccountLoading: false,
    fetchAppsByAccountFailure: false,
    fetchAppsByAccountSuccess: false,
    appByAccount: [],
    fetchParameterByAppAccountLoading: false,
    fetchParameterByAppAccountFailure: false,
    fetchParameterByAppAccountSuccess: false,
    parameterByAppAccount: [],
    editAppStatusLoading: false,
    editAppStatusFailure: false,
    editAppStatusSuccess: false,
    deleteRestoreAppLoading: false,
    deleteRestoreAppFailure: false,
    deleteRestoreAppSuccess: false,
    fetchAppFromTrashLoading: false,
    fetchAppFromTrashFailure: false,
    fetchAppFromTrashSuccess: false,
    appTrashList: [],
    doMultiTaskingAppLoading: false,
    doMultiTaskingAppFailure: false,
    doMultiTaskingAppSuccess: false,
    fetchChartListLoading: false,
    fetchChartListFailure: false,
    fetchChartListSuccess: false,
    chartListData: [],
    fetchLocationWiseDataLoading: false,
    fetchLocationWiseDataFailure: false,
    fetchLocationWiseDataSuccess: false,
    locationListData: [],
    fetchAllAppsChartListLoading: false,
    fetchAllAppsChartListFailure: false,
    fetchAllAppsChartListSuccess: false,
    allAppsChartListData: {},
    fetchAllAppsChartListByFilterLoading: false,
    fetchAllAppsChartListByFilterFailure: false,
    fetchAllAppsChartListByFilterSuccess: false,
    allAppsChartListByFilterData: {},
    fetchAllChartListAppLoading: false,
    fetchAllChartListAppFailure: false,
    fetchAllChartListAppSuccess: false,
    chartListByApp: [],
    fetchAllChartListAccountLoading: false,
    fetchAllChartListAccountFailure: false,
    fetchAllChartListAccountSuccess: false,
    chartListByAccount: [],
    checkPackageNameLoading: false,
    checkPackageNameFailure: false,
    checkPackageNameSuccess: false,
    checkPackageNameData: {}
});