import React, {useEffect, useState} from 'react'
import leftArrow from "../../assets/icons/left-arrow.png";
import rightArrow from "../../assets/icons/right-arrow.png";
import {Pagination, Select} from "antd";
import "./Pagination.scss";

const {Option} = Select;

const CustomPagination = props => {
    const {
        dataArray,
        pageSize,
        viewPageSelect,
        setData,
        totalCount,
        setCurrentPage,
        paginationData,
        setPaginationData,
        viewPagination
    } = props;

    const pageSizeOptions = [5, 10, 20, 50, 100];

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: pageSize || 10,
        total: totalCount
    });

    useEffect(() => {
        setPagination(prevState => ({
            ...prevState,
            current: paginationData?.current || 1,
            pageSize: paginationData?.pageSize || 10,
            total: paginationData?.total || totalCount
        }));
    }, [paginationData]);

    useEffect(() => {
        setPagination({...pagination, total: totalCount});
    }, [totalCount]);

    useEffect(() => {
        setCurrentPage && setCurrentPage(pagination?.current)
    }, [pagination?.current]);

    useEffect(() => {
        if (!viewPagination && setData) setData(paginate(1));
    }, [])

    const onPaginationSettings = (key, value) => {
        if (viewPagination) {
            setPagination(prevState => ({
                ...prevState,
                [key]: value
            }));
            let obj = {page: pagination.current || 1, pageSize: pagination.pageSize || 10};
            if (key === "current") obj.page = value;
            if (key === "pageSize") {
                obj.page = 1;
                obj.pageSize = value; // ADDEDD BY ME
                setPagination(prevState => ({
                    ...prevState,
                    page: 1,
                    current: 1
                }));
            }
            setData && setData(obj)
        } else {
            setPagination(prevState => ({
                ...prevState,
                [key]: value
            }));
            setData && setData(paginate(value))
        }
    };

    function paginate(page_number) {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return dataArray.slice((page_number - 1) * pagination.pageSize, page_number * pagination.pageSize);
    }


    const paginationProps = {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
        responsive: true,
        itemRender: (current, type, originalElement) => {
            if (type === 'prev') {
                return <span><img src={leftArrow} alt="left"/></span>;
            }
            if (type === 'next') {
                return <span><img src={rightArrow} alt="right"/></span>;
            }
            return originalElement;
        },
        onChange: (pageNumber) => onPaginationSettings('current', pageNumber)
    };

    const renderItemsString = `${paginationProps.current > 1 ?
        (pagination.total > pagination.current * pagination.pageSize) ?
            ((paginationProps.current - 1) * paginationProps.pageSize) + 1 : pagination.total > pagination.pageSize && pagination.pageSize + 1 || 1 : 1}
      -${paginationProps.total > ((paginationProps.current - 1) * paginationProps.pageSize) + (paginationProps.pageSize)
        ? ((paginationProps.current - 1) * paginationProps.pageSize) + (paginationProps.pageSize)
        : paginationProps.total || 1
    } items of ${paginationProps.total || ""}`;


    return (
        <div className="custom-pagination fs-13 mt-2">
            {/*<div className="m-auto">*/}
            {viewPagination ?
                <div className="custom-pagination fs-13 w-100 mt-2 justify-content-between d-flex">
                    {viewPageSelect &&
                        <>
                            <div className="d-inline-flex align-center gap-10">
                                View{" "}
                                <Select value={paginationProps.pageSize} style={{width: 70}} className='fs-13'
                                        onChange={value => onPaginationSettings('pageSize', parseInt(value))}>
                                    {pageSizeOptions.map((opt, key) => <Option key={key} value={opt}>{opt}</Option>)}
                                </Select>{" "}
                                Items per page &nbsp;
                            </div>
                            <div className="mr-5">
                                {renderItemsString}
                            </div>
                        </>}
                    <Pagination className='fs-13' {...paginationProps} />
                    {!viewPageSelect && <div>
                        Showing 61 t0 71 of 298 (30 Pages)
                    </div>}
                </div>
                :
                <Pagination {...paginationProps} />
            }
            {/*</div>*/}
        </div>
    )
};

export default CustomPagination
