import {combineReducers} from "redux";
import {all} from "redux-saga/effects";


//COMMON
import {dashboardReducer} from "./Dashboard/dashboardReducer";
import {allAppsReducer} from "./AllApps/allAppsReducer";
import {platformReducer} from "./Platform/platformReducer";
import {authReducer} from "./Auth/authReducer";
import {categoryReducer} from "./Category/categoryReducer";
import {userReducer} from "./User/userReducer";
import {testKeysReducer} from "./TestKeys/testKeysReducer";
import {otherKeysAccessReducer} from "./OtherKeysAccess/otherKeysAccessReducer";
import {notificationReducer} from "./Notification/notificationReducer";
import {consoleReducer} from "./ConsoleList/consoleReducer";
import {addExtraReducer} from "./AddExtraField/addExtraReducer";

import dashboardSaga from "./Dashboard/dashboardSaga";
import allAppsSaga from "./AllApps/allAppsSaga";
import platformSaga from "./Platform/platformSaga";
import authSaga from "./Auth/authSaga";
import categorySaga from "./Category/categorySaga";
import userSaga from "./User/userSaga";
import testKeySaga from "./TestKeys/testKeysSaga";
import otherKeySaga from "./OtherKeysAccess/otherKeysAccessSaga";
import notificationSaga from "./Notification/notificationSaga";
import consoleSaga from "./ConsoleList/consoleSaga";
import addExtraSaga from "./AddExtraField/addExtraSaga";

export const reducers = () =>
    combineReducers({
        dashboard: dashboardReducer,
        allApps: allAppsReducer,
        platform: platformReducer,
        authorization: authReducer,
        category: categoryReducer,
        users: userReducer,
        testKeys: testKeysReducer,
        otherKeys: otherKeysAccessReducer,
        notification: notificationReducer,
        consoleManage: consoleReducer,
        addExtra: addExtraReducer,
    });

export const rootSaga = function* () {
    yield all([
        dashboardSaga(),
        allAppsSaga(),
        platformSaga(),
        authSaga(),
        categorySaga(),
        userSaga(),
        testKeySaga(),
        otherKeySaga(),
        notificationSaga(),
        consoleSaga(),
        addExtraSaga(),
    ]);
};
