import {handleActions} from "redux-actions";
import {initialDashboardState} from "./initialDashboardState";
import {DashboardConstants} from "./dashboardConstants";
import {
    requestFail, requestPending, requestSuccess
} from "../../../utils/fetch";

export const dashboardReducer = handleActions({
    [requestSuccess(DashboardConstants.FETCH_ACCOUNT_LIST)]: (state, action) => ({
        ...state,
        fetchDashboardLoading: false,
        fetchDashboardFailure: false,
        fetchDashboardSuccess: true,
        dashboardList: (action.payload?.data || [])
    }), [requestPending(DashboardConstants.FETCH_ACCOUNT_LIST)]: state => ({
        ...state,
        fetchDashboardLoading: true,
        fetchDashboardFailure: false,
        fetchDashboardSuccess: false,
        dashboardList: []
    }), [requestFail(DashboardConstants.FETCH_ACCOUNT_LIST)]: state => ({
        ...state,
        fetchDashboardLoading: false,
        fetchDashboardFailure: true,
        fetchDashboardSuccess: false,
        dashboardList: []
    }),
    [requestSuccess(DashboardConstants.GET_ACCOUNT_TRASH_LIST)]: (state, action) => ({
        ...state,
        trashAccountListLoading: false,
        trashAccountListFailure: false,
        trashAccountListSuccess: true,
        trashAccountList: (action.payload?.data || [])
    }), [requestPending(DashboardConstants.GET_ACCOUNT_TRASH_LIST)]: state => ({
        ...state,
        trashAccountListLoading: true,
        trashAccountListFailure: false,
        trashAccountListSuccess: false,
        trashAccountList: []
    }), [requestFail(DashboardConstants.GET_ACCOUNT_TRASH_LIST)]: state => ({
        ...state,
        trashAccountListLoading: false,
        trashAccountListFailure: true,
        trashAccountListSuccess: false,
        trashAccountList: []
    }),
    [requestSuccess(DashboardConstants.CREATE_ACCOUNT)]: (state, action) => ({
        ...state,
        createAccountLoading: false,
        createAccountFailure: false,
        createAccountSuccess: true,
    }), [requestPending(DashboardConstants.CREATE_ACCOUNT)]: state => ({
        ...state,
        createAccountLoading: true,
        createAccountFailure: false,
        createAccountSuccess: false,
    }), [requestFail(DashboardConstants.CREATE_ACCOUNT)]: state => ({
        ...state,
        createAccountLoading: false,
        createAccountFailure: true,
        createAccountSuccess: false,
    }),
    [requestSuccess(DashboardConstants.FETCH_ACCOUNT_BY_ID)]: (state, action) => ({
        ...state,
        fetchAccountByIdLoading: false,
        fetchAccountByIdFailure: false,
        fetchAccountByIdSuccess: true,
        accountById: (action.payload?.data || {})
    }), [requestPending(DashboardConstants.FETCH_ACCOUNT_BY_ID)]: state => ({
        ...state,
        fetchAccountByIdLoading: true,
        fetchAccountByIdFailure: false,
        fetchAccountByIdSuccess: false,
        accountById: {}
    }), [requestFail(DashboardConstants.FETCH_ACCOUNT_BY_ID)]: state => ({
        ...state,
        fetchAccountByIdLoading: false,
        fetchAccountByIdFailure: true,
        fetchAccountByIdSuccess: false,
        accountById: {}
    }),
    [requestSuccess(DashboardConstants.FETCH_APP_LIST_BY_ID_ACCOUNT)]: (state, action) => ({
        ...state,
        fetchAppByAccountIdLoading: false,
        fetchAppByAccountIdFailure: false,
        fetchAppByAccountIdSuccess: true,
        appListByAccountId: (action.payload?.data || [])
    }), [requestPending(DashboardConstants.FETCH_APP_LIST_BY_ID_ACCOUNT)]: state => ({
        ...state,
        fetchAppByAccountIdLoading: true,
        fetchAppByAccountIdFailure: false,
        fetchAppByAccountIdSuccess: false,
        appListByAccountId: []
    }), [requestFail(DashboardConstants.FETCH_APP_LIST_BY_ID_ACCOUNT)]: state => ({
        ...state,
        fetchAppByAccountIdLoading: false,
        fetchAppByAccountIdFailure: true,
        fetchAppByAccountIdSuccess: false,
        appListByAccountId: []
    }),
    [requestSuccess(DashboardConstants.EDIT_ACCOUNT)]: (state, action) => ({
        ...state,
        editAccountLoading: false,
        editAccountFailure: false,
        editAccountSuccess: true,
    }), [requestPending(DashboardConstants.EDIT_ACCOUNT)]: state => ({
        ...state,
        editAccountLoading: true,
        editAccountFailure: false,
        editAccountSuccess: false,
    }), [requestFail(DashboardConstants.EDIT_ACCOUNT)]: state => ({
        ...state,
        editAccountLoading: false,
        editAccountFailure: true,
        editAccountSuccess: false,
    }),
    [requestSuccess(DashboardConstants.DELETE_ACCOUNT)]: (state, action) => ({
        ...state,
        fetchTrashAccountLoading: false,
        fetchTrashAccountFailure: false,
        fetchTrashAccountSuccess: true,
    }), [requestPending(DashboardConstants.DELETE_ACCOUNT)]: state => ({
        ...state,
        fetchTrashAccountLoading: true,
        fetchTrashAccountFailure: false,
        fetchTrashAccountSuccess: false,
    }), [requestFail(DashboardConstants.DELETE_ACCOUNT)]: state => ({
        ...state,
        fetchTrashAccountLoading: false,
        fetchTrashAccountFailure: true,
        fetchTrashAccountSuccess: false,
    }),
    [requestSuccess(DashboardConstants.DELETE_RESTORE_TRASH_ACCOUNT)]: (state, action) => ({
        ...state,
        deleteRestoreAccountLoading: false,
        deleteRestoreAccountFailure: false,
        deleteRestoreAccountSuccess: true,
    }), [requestPending(DashboardConstants.DELETE_RESTORE_TRASH_ACCOUNT)]: state => ({
        ...state,
        deleteRestoreAccountLoading: true,
        deleteRestoreAccountFailure: false,
        deleteRestoreAccountSuccess: false,
    }), [requestFail(DashboardConstants.DELETE_RESTORE_TRASH_ACCOUNT)]: state => ({
        ...state,
        deleteRestoreAccountLoading: false,
        deleteRestoreAccountFailure: true,
        deleteRestoreAccountSuccess: false,
    }),
    [requestSuccess(DashboardConstants.DELETE_RESTORE_FROM_TRASH_ACCOUNT)]: (state, action) => ({
        ...state,
        deleteRestoreTrashFromAccountLoading: false,
        deleteRestoreTrashFromAccountFailure: false,
        deleteRestoreTrashFromAccountSuccess: true,
    }), [requestPending(DashboardConstants.DELETE_RESTORE_FROM_TRASH_ACCOUNT)]: state => ({
        ...state,
        deleteRestoreTrashFromAccountLoading: true,
        deleteRestoreTrashFromAccountFailure: false,
        deleteRestoreTrashFromAccountSuccess: false,
    }), [requestFail(DashboardConstants.DELETE_RESTORE_FROM_TRASH_ACCOUNT)]: state => ({
        ...state,
        deleteRestoreTrashFromAccountLoading: false,
        deleteRestoreTrashFromAccountFailure: true,
        deleteRestoreTrashFromAccountSuccess: false,
    }),
}, initialDashboardState());
