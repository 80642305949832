import React from 'react'
import "./TabBar.scss"

const TabBar = props => {
    const {labelList, simpleTab, index, onClick,minWidth,tabBtnClass} = props;
    return (
        <>
            {simpleTab ?
                <div className={`simpleTab tabBar p-0 m-0 `} style={{minWidth:minWidth}}>
                    <ul className="nav nav-pills mt-1px">
                        {labelList.map((item, key) => {
                            let isActive = (item.value || item.value === "") ? index === item.value : index === key;
                            return (
                                <li onClick={() => onClick((item.value || item.value === "") ? item.value : key)} className={`nav-item${isActive ? "-active" : ""} border-radius-4`}
                                    key={key}>
                                    <a className={`nav-link fs-14 fw-500 ${tabBtnClass} button${isActive ? "-active" : ""}`}>{item.label}</a>
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>
                :
                <div className={`tabBar `} style={{minWidth:minWidth}}>
                    <ul className="nav nav-pills nav-fill mt-1px">
                        {labelList.map((item, key) => {
                            let isActive = (item.value || item.value === "") ? index === item.value : index === key;
                            return (
                                <li onClick={() => onClick((item.value || item.value === "") ? item.value : key)} className="nav-item"
                                    key={key}>
                                    <a className={`nav-link fs-14 pmt-5px button${isActive ? "-active" : ""}`}>{item.label}</a>
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>
            }
        </>
    )
};

export default TabBar
