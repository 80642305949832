import {handleActions} from "redux-actions";
import {initialAllAppsState} from "./initialAllAppsState";
import {AllAppsConstants} from "./allAppsConstants";
import {
    requestFail, requestPending, requestSuccess
} from "../../../utils/fetch";

export const allAppsReducer = handleActions({
    [requestSuccess(AllAppsConstants.FETCH_APP_LIST)]: (state, action) => ({
        ...state,
        fetchAllAppsLoading: false,
        fetchAllAppsFailure: false,
        fetchAllAppsSuccess: true,
        appsList: action.payload?.data || []
    }), [requestPending(AllAppsConstants.FETCH_APP_LIST)]: state => ({
        ...state,
        fetchAllAppsLoading: true,
        fetchAllAppsFailure: false,
        fetchAllAppsSuccess: false,
        appsList: []
    }), [requestFail(AllAppsConstants.FETCH_APP_LIST)]: state => ({
        ...state,
        fetchAllAppsLoading: false,
        fetchAllAppsFailure: true,
        fetchAllAppsSuccess: false,
        appsList: []
    }),
    [requestSuccess(AllAppsConstants.FETCH_APP_LIST_BY_PAGE)]: (state, action) => ({
        ...state,
        fetchAllAppsByPageLoading: false,
        fetchAllAppsByPageFailure: false,
        fetchAllAppsByPageSuccess: true,
        appsListByPage: action.payload?.data || {}
    }), [requestPending(AllAppsConstants.FETCH_APP_LIST_BY_PAGE)]: state => ({
        ...state,
        fetchAllAppsByPageLoading: true,
        fetchAllAppsByPageFailure: false,
        fetchAllAppsByPageSuccess: false,
        appsListByPage: {}
    }), [requestFail(AllAppsConstants.FETCH_APP_LIST_BY_PAGE)]: state => ({
        ...state,
        fetchAllAppsByPageLoading: false,
        fetchAllAppsByPageFailure: true,
        fetchAllAppsByPageSuccess: false,
        appsListByPage: {}
    }),
    [requestSuccess(AllAppsConstants.CREATE_APP)]: (state) => ({
        ...state,
        createAppLoading: false,
        createAppFailure: false,
        createAppSuccess: true,
    }), [requestPending(AllAppsConstants.CREATE_APP)]: state => ({
        ...state,
        createAppLoading: true,
        createAppFailure: false,
        createAppSuccess: false,
    }), [requestFail(AllAppsConstants.CREATE_APP)]: state => ({
        ...state,
        createAppLoading: false,
        createAppFailure: true,
        createAppSuccess: false,
    }),
    [requestSuccess(AllAppsConstants.FETCH_APP_LIST_BY_ID)]: (state, action) => ({
        ...state,
        fetchAllAppsByIDLoading: false,
        fetchAllAppsByIDFailure: false,
        fetchAllAppsByIDSuccess: true,
        appByID: action.payload?.data || {}
    }), [requestPending(AllAppsConstants.FETCH_APP_LIST_BY_ID)]: state => ({
        ...state,
        fetchAllAppsByIDLoading: true,
        fetchAllAppsByIDFailure: false,
        fetchAllAppsByIDSuccess: false,
        appByID: {}
    }), [requestFail(AllAppsConstants.FETCH_APP_LIST_BY_ID)]: state => ({
        ...state,
        fetchAllAppsByIDLoading: false,
        fetchAllAppsByIDFailure: true,
        fetchAllAppsByIDSuccess: false,
        appByID: {}
    }),
    [requestSuccess(AllAppsConstants.EDIT_APP)]: (state) => ({
        ...state,
        editAppLoading: false,
        editAppFailure: false,
        editAppSuccess: true,
    }), [requestPending(AllAppsConstants.EDIT_APP)]: state => ({
        ...state,
        editAppLoading: true,
        editAppFailure: false,
        editAppSuccess: false,
    }), [requestFail(AllAppsConstants.EDIT_APP)]: state => ({
        ...state,
        editAppLoading: false,
        editAppFailure: true,
        editAppSuccess: false,
    }),
    [requestSuccess(AllAppsConstants.EDIT_APP_STATUS)]: (state) => ({
        ...state,
        editAppStatusLoading: false,
        editAppStatusFailure: false,
        editAppStatusSuccess: true,
    }), [requestPending(AllAppsConstants.EDIT_APP_STATUS)]: state => ({
        ...state,
        editAppStatusLoading: true,
        editAppStatusFailure: false,
        editAppStatusSuccess: false,
    }), [requestFail(AllAppsConstants.EDIT_APP_STATUS)]: state => ({
        ...state,
        editAppStatusLoading: false,
        editAppStatusFailure: true,
        editAppStatusSuccess: false,
    }),
    [requestSuccess(AllAppsConstants.DELETE_APP)]: (state) => ({
        ...state,
        deleteAppLoading: false,
        deleteAppFailure: false,
        deleteAppSuccess: true,
    }), [requestPending(AllAppsConstants.DELETE_APP)]: (state, action) => ({
        ...state,
        deleteAppLoading: true,
        deleteAppFailure: false,
        deleteAppSuccess: false
    })
    , [requestFail(AllAppsConstants.DELETE_APP)]: state => ({
        ...state,
        deleteAppLoading: false,
        deleteAppFailure: true,
        deleteAppSuccess: false,
    }),
    [requestSuccess(AllAppsConstants.DELETE_RESTORE_TRASH_APP)]: (state) => ({
        ...state,
        deleteRestoreAppLoading: false,
        deleteRestoreAppFailure: false,
        deleteRestoreAppSuccess: true,
    }), [requestPending(AllAppsConstants.DELETE_RESTORE_TRASH_APP)]: (state, action) => ({
        ...state,
        deleteRestoreAppLoading: true,
        deleteRestoreAppFailure: false,
        deleteRestoreAppSuccess: false
    })
    , [requestFail(AllAppsConstants.DELETE_RESTORE_TRASH_APP)]: state => ({
        ...state,
        deleteRestoreAppLoading: false,
        deleteRestoreAppFailure: true,
        deleteRestoreAppSuccess: false,
    }),
    [requestSuccess(AllAppsConstants.FETCH_APP_LIST_BY_ACCOUNT)]: (state, action) => ({
        ...state,
        fetchAppsByAccountLoading: false,
        fetchAppsByAccountFailure: false,
        fetchAppsByAccountSuccess: true,
        appByAccount: action.payload?.data || []
    }), [requestPending(AllAppsConstants.FETCH_APP_LIST_BY_ACCOUNT)]: state => ({
        ...state,
        fetchAppsByAccountLoading: true,
        fetchAppsByAccountFailure: false,
        fetchAppsByAccountSuccess: false,
        appByAccount: []
    }), [requestFail(AllAppsConstants.FETCH_APP_LIST_BY_ACCOUNT)]: state => ({
        ...state,
        fetchAppsByAccountLoading: false,
        fetchAppsByAccountFailure: true,
        fetchAppsByAccountSuccess: false,
        appByAccount: []
    }),
    [requestSuccess(AllAppsConstants.FETCH_PARAMETER_BY_APP_ACCOUNT)]: (state, action) => ({
        ...state,
        fetchParameterByAppAccountLoading: false,
        fetchParameterByAppAccountFailure: false,
        fetchParameterByAppAccountSuccess: true,
        parameterByAppAccount: action.payload?.data || []
    }), [requestPending(AllAppsConstants.FETCH_PARAMETER_BY_APP_ACCOUNT)]: state => ({
        ...state,
        fetchParameterByAppAccountLoading: true,
        fetchParameterByAppAccountFailure: false,
        fetchParameterByAppAccountSuccess: false,
        parameterByAppAccount: []
    }), [requestFail(AllAppsConstants.FETCH_PARAMETER_BY_APP_ACCOUNT)]: state => ({
        ...state,
        fetchParameterByAppAccountLoading: false,
        fetchParameterByAppAccountFailure: true,
        fetchParameterByAppAccountSuccess: false,
        parameterByAppAccount: []
    }),
    [requestSuccess(AllAppsConstants.FETCH_APP_FROM_TRASH)]: (state, action) => ({
        ...state,
        fetchAppFromTrashLoading: false,
        fetchAppFromTrashFailure: false,
        fetchAppFromTrashSuccess: true,
        appTrashList: action.payload?.data || []
    }), [requestPending(AllAppsConstants.FETCH_APP_FROM_TRASH)]: state => ({
        ...state,
        fetchAppFromTrashLoading: true,
        fetchAppFromTrashFailure: false,
        fetchAppFromTrashSuccess: false,
        appTrashList: []
    }), [requestFail(AllAppsConstants.FETCH_APP_FROM_TRASH)]: state => ({
        ...state,
        fetchAppFromTrashLoading: false,
        fetchAppFromTrashFailure: true,
        fetchAppFromTrashSuccess: false,
        appTrashList: []
    }),
    [requestSuccess(AllAppsConstants.DO_MUTLTI_TASKING)]: (state) => ({
        ...state,
        doMultiTaskingAppLoading: false,
        doMultiTaskingAppFailure: false,
        doMultiTaskingAppSuccess: true,
    }), [requestPending(AllAppsConstants.DO_MUTLTI_TASKING)]: (state, action) => ({
        ...state,
        doMultiTaskingAppLoading: true,
        doMultiTaskingAppFailure: false,
        doMultiTaskingAppSuccess: false
    })
    , [requestFail(AllAppsConstants.DO_MUTLTI_TASKING)]: state => ({
        ...state,
        doMultiTaskingAppLoading: false,
        doMultiTaskingAppFailure: true,
        doMultiTaskingAppSuccess: false,
    }),
    [requestSuccess(AllAppsConstants.FETCH_CHART_DATA)]: (state, action) => ({
        ...state,
        fetchChartListLoading: false,
        fetchChartListFailure: false,
        fetchChartListSuccess: true,
        chartListData: action.payload?.data || []
    }), [requestPending(AllAppsConstants.FETCH_CHART_DATA)]: state => ({
        ...state,
        fetchChartListLoading: true,
        fetchChartListFailure: false,
        fetchChartListSuccess: false,
        chartListData: []
    }), [requestFail(AllAppsConstants.FETCH_CHART_DATA)]: state => ({
        ...state,
        fetchChartListLoading: false,
        fetchChartListFailure: true,
        fetchChartListSuccess: false,
        chartListData: []
    }),
    [requestSuccess(AllAppsConstants.FETCH_ALL_APP_CHART)]: (state, action) => ({
        ...state,
        fetchAllAppsChartListLoading: false,
        fetchAllAppsChartListFailure: false,
        fetchAllAppsChartListSuccess: true,
        allAppsChartListData: action.payload?.data || {}
    }), [requestPending(AllAppsConstants.FETCH_ALL_APP_CHART)]: state => ({
        ...state,
        fetchAllAppsChartListLoading: true,
        fetchAllAppsChartListFailure: false,
        fetchAllAppsChartListSuccess: false,
        allAppsChartListData: {}
    }), [requestFail(AllAppsConstants.FETCH_ALL_APP_CHART)]: state => ({
        ...state,
        fetchAllAppsChartListLoading: false,
        fetchAllAppsChartListFailure: true,
        fetchAllAppsChartListSuccess: false,
        allAppsChartListData: {}
    }),
    [requestSuccess(AllAppsConstants.DO_MULTI_TASKING_TRASH)]: (state) => ({
        ...state,
        doMultiTaskingForTrashAppLoading: false,
        doMultiTaskingForTrashAppFailure: false,
        doMultiTaskingForTrashAppSuccess: true,
    }), [requestPending(AllAppsConstants.DO_MULTI_TASKING_TRASH)]: (state, action) => ({
        ...state,
        doMultiTaskingForTrashAppLoading: true,
        doMultiTaskingForTrashAppFailure: false,
        doMultiTaskingForTrashAppSuccess: false
    })
    , [requestFail(AllAppsConstants.DO_MULTI_TASKING_TRASH)]: state => ({
        ...state,
        doMultiTaskingForTrashAppLoading: false,
        doMultiTaskingForTrashAppFailure: true,
        doMultiTaskingForTrashAppSuccess: false,
    }),
    [requestSuccess(AllAppsConstants.FETCH_LOCATION_DATA)]: (state, action) => ({
        ...state,
        fetchLocationWiseDataLoading: false,
        fetchLocationWiseDataFailure: false,
        fetchLocationWiseDataSuccess: true,
        locationListData: action.payload?.data || []
    }), [requestPending(AllAppsConstants.FETCH_LOCATION_DATA)]: state => ({
        ...state,
        fetchLocationWiseDataLoading: true,
        fetchLocationWiseDataFailure: false,
        fetchLocationWiseDataSuccess: false,
        locationListData: []
    }), [requestFail(AllAppsConstants.FETCH_LOCATION_DATA)]: state => ({
        ...state,
        fetchLocationWiseDataLoading: false,
        fetchLocationWiseDataFailure: true,
        fetchLocationWiseDataSuccess: false,
        locationListData: []
    }),
    [requestSuccess(AllAppsConstants.FETCH_ALL_APP_CHART_FILTER)]: (state, action) => ({
        ...state,
        fetchAllAppsChartListByFilterLoading: false,
        fetchAllAppsChartListByFilterFailure: false,
        fetchAllAppsChartListByFilterSuccess: true,
        allAppsChartListByFilterData: action.payload?.data || {}
    }), [requestPending(AllAppsConstants.FETCH_ALL_APP_CHART_FILTER)]: state => ({
        ...state,
        fetchAllAppsChartListByFilterLoading: true,
        fetchAllAppsChartListByFilterFailure: false,
        fetchAllAppsChartListByFilterSuccess: false,
        allAppsChartListByFilterData: {}
    }), [requestFail(AllAppsConstants.FETCH_ALL_APP_CHART_FILTER)]: state => ({
        ...state,
        fetchAllAppsChartListByFilterLoading: false,
        fetchAllAppsChartListByFilterFailure: true,
        fetchAllAppsChartListByFilterSuccess: false,
        allAppsChartListByFilterData: {}
    }),
    [requestSuccess(AllAppsConstants.FETCH_ALL_CHART_APP)]: (state, action) => ({
        ...state,
        fetchAllChartListAppLoading: false,
        fetchAllChartListAppFailure: false,
        fetchAllChartListAppSuccess: true,
        chartListByApp: action.payload?.data || []
    }), [requestPending(AllAppsConstants.FETCH_ALL_CHART_APP)]: state => ({
        ...state,
        fetchAllChartListAppLoading: true,
        fetchAllChartListAppFailure: false,
        fetchAllChartListAppSuccess: false,
        chartListByApp: []
    }), [requestFail(AllAppsConstants.FETCH_ALL_CHART_APP)]: state => ({
        ...state,
        fetchAllChartListAppLoading: false,
        fetchAllChartListAppFailure: true,
        fetchAllChartListAppSuccess: false,
        chartListByApp: []
    }),
    [requestSuccess(AllAppsConstants.FETCH_ALL_CHART_ACCOUNT)]: (state, action) => ({
        ...state,
        fetchAllChartListAccountLoading: false,
        fetchAllChartListAccountFailure: false,
        fetchAllChartListAccountSuccess: true,
        chartListByAccount: action.payload?.data || []
    }), [requestPending(AllAppsConstants.FETCH_ALL_CHART_ACCOUNT)]: state => ({
        ...state,
        fetchAllChartListAccountLoading: true,
        fetchAllChartListAccountFailure: false,
        fetchAllChartListAccountSuccess: false,
        chartListByAccount: []
    }), [requestFail(AllAppsConstants.FETCH_ALL_CHART_ACCOUNT)]: state => ({
        ...state,
        fetchAllChartListAccountLoading: false,
        fetchAllChartListAccountFailure: true,
        fetchAllChartListAccountSuccess: false,
        chartListByAccount: []
    }),
    [requestSuccess(AllAppsConstants.CHECK_PACKAGE_NAME)]: (state, action) => ({
        ...state,
        checkPackageNameLoading: false,
        checkPackageNameFailure: false,
        checkPackageNameSuccess: true,
        checkPackageNameData: action.payload?.data || {}
    }), [requestPending(AllAppsConstants.CHECK_PACKAGE_NAME)]: state => ({
        ...state,
        checkPackageNameLoading: true,
        checkPackageNameFailure: false,
        checkPackageNameSuccess: false,
        checkPackageNameData: {}
    }), [requestFail(AllAppsConstants.CHECK_PACKAGE_NAME)]: state => ({
        ...state,
        checkPackageNameLoading: false,
        checkPackageNameFailure: true,
        checkPackageNameSuccess: false,
        checkPackageNameData: {}
    }),
}, initialAllAppsState());
