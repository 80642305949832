const PlatformIcon = () => {
    return (
        <svg width="24px" height="24px" viewBox="0 0 1024 1024" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
            <path className='platform-icon'
                d="M102.107429 678.107429v-365.714286H59.099429C39.204571 311.076571 23.771429 296.96 23.771429 279.771429c0-17.188571 15.433143-31.451429 35.328-32.548572h43.008V147.675429c0-70.729143 57.563429-128.292571 128.292571-128.292572h606.061714c70.802286 0 128.292571 57.563429 128.292572 128.292572v691.931428a128.292571 128.292571 0 0 1-128.292572 128.438857H230.4a128.365714 128.365714 0 0 1-128.292571-128.292571v-93.330286H59.099429C38.765714 745.691429 22.674286 730.624 22.674286 712.265143c0-18.285714 16.091429-33.426286 36.425143-34.157714h43.008z m62.902857 0h36.571428c19.968 1.243429 35.401143 16.091429 35.401143 34.157714 0 17.993143-15.433143 32.914286-35.401143 34.157714h-36.571428v98.523429c0 33.572571 27.282286 60.781714 60.708571 60.781714H841.142857c33.499429 0 60.708571-27.209143 60.708572-60.781714V142.482286a60.928 60.928 0 0 0-60.708572-60.708572H225.792v-0.073143a60.854857 60.854857 0 0 0-60.708571 60.708572v104.886857h36.498285c20.918857 0 37.888 14.628571 37.888 32.548571-0.073143 17.92-17.042286 32.475429-37.888 32.475429h-36.571428v365.787429z m523.556571-365.714286H364.982857a40.448 40.448 0 0 1-26.185143-10.971429 29.769143 29.769143 0 0 1-9.435428-23.552c1.170286-16.457143 16.457143-29.622857 35.620571-30.573714h323.584c20.845714 0 38.034286 14.628571 38.034286 32.548571-0.219429 17.92-17.115429 32.475429-38.034286 32.475429z m0 215.478857H364.982857c-20.114286-1.024-35.84-16.018286-35.84-34.157714 0-18.139429 15.725714-33.133714 35.84-34.157715h323.584c20.114286 1.024 35.84 16.018286 35.84 34.157715 0 18.139429-15.725714 33.133714-35.84 34.157714z m0 218.550857H354.157714a39.643429 39.643429 0 0 1-26.112-11.483428 32.109714 32.109714 0 0 1-9.435428-24.722286c1.097143-17.261714 16.384-31.085714 35.547428-32.109714h334.336a39.570286 39.570286 0 0 1 26.185143 11.410285 32.036571 32.036571 0 0 1 9.508572 24.795429c-1.097143 17.261714-16.384 31.085714-35.620572 32.109714z"
                fill="#000000"/>
        </svg>
    )
};
export default PlatformIcon;
