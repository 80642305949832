import {call, takeLatest, takeLeading} from "redux-saga/effects";
//
import {request} from "../../../utils/fetch";
//
import {ConsoleConstants} from "./consoleConstants";

// APIS
import {config} from "../../../utils/config";
import * as API from "../../../utils/apiConsts";

function* fetchConsoleList(action) {
    yield call(
        request({
            type: ConsoleConstants.FETCH_CONSOLE_LIST,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.ConsoleBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* createConsole(action) {
    yield call(
        request({
            type: ConsoleConstants.CREATE_CONSOLE,
            method: "POST",
            url: `${API.ConsoleBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
            baseURL: config.BASE_URL,
        }),
        action
    );
}

function* consoleLogin(action) {
    yield call(
        request({
            type: ConsoleConstants.LOGIN_TO_CONSOLE,
            method: "POST",
            url: `${API.ConsolePasswordBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
            baseURL: config.BASE_URL,
        }),
        action
    );
}

function* consolePasswordUpdate(action) {
    yield call(
        request({
            type: ConsoleConstants.UPDATE_CONSOLE_PASSWORD,
            method: "PUT",
            url: `${API.ConsolePasswordBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
            baseURL: config.BASE_URL,
        }),
        action
    );
}

function* fetchConsoleById(action) {
    yield call(
        request({
            type: ConsoleConstants.FETCH_CONSOLE_BY_ID,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.ConsoleBase}/${action.payload.id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* checkConsoleById(action) {
    yield call(
        request({
            type: ConsoleConstants.CHECK_CONSOLE_BY_ID,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.ConsolePasswordBase}/${action?.payload?.data?.id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* editConsole(action) {
    yield call(
        request({
            type: ConsoleConstants.EDIT_CONSOLE,
            method: "PUT",
            baseURL: config.BASE_URL,
            url: `${API.ConsoleBase}/${action.payload.data._id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* deleteConsole(action) {
    yield call(
        request({
            type: ConsoleConstants.DELETE_CONSOLE,
            method: "DELETE",
            baseURL: config.BASE_URL,
            url: `${API.ConsoleBase}/${action.payload?.data?._id}`,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

export default function* rootSaga() {
    yield takeLeading(ConsoleConstants.FETCH_CONSOLE_LIST, fetchConsoleList);
    yield takeLatest(ConsoleConstants.CREATE_CONSOLE, createConsole);
    yield takeLatest(ConsoleConstants.LOGIN_TO_CONSOLE, consoleLogin);
    yield takeLatest(ConsoleConstants.UPDATE_CONSOLE_PASSWORD, consolePasswordUpdate);
    yield takeLatest(ConsoleConstants.EDIT_CONSOLE, editConsole);
    yield takeLeading(ConsoleConstants.FETCH_CONSOLE_BY_ID, fetchConsoleById);
    yield takeLeading(ConsoleConstants.CHECK_CONSOLE_BY_ID, checkConsoleById);
    yield takeLatest(ConsoleConstants.DELETE_CONSOLE, deleteConsole);
}
