import {call, takeLatest, takeLeading, takeEvery} from "redux-saga/effects";
//
import {request} from "../../../utils/fetch";

//Constants
import {DashboardConstants} from "./dashboardConstants";

// APIS
import {config} from "../../../utils/config";
import * as API from "../../../utils/apiConsts";


function* fetchDashboardAccountList(action) {
    yield call(
        request({
            type: DashboardConstants.FETCH_ACCOUNT_LIST,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.AccountBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* createAccountData(action) {
    yield call(
        request({
            type: DashboardConstants.CREATE_ACCOUNT,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.AccountBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* fetchAccountById(action) {
    yield call(
        request({
            type: DashboardConstants.FETCH_ACCOUNT_BY_ID,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.AccountBase}/${action.payload.id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* fetchAPPByAccountId(action) {
    yield call(
        request({
            type: DashboardConstants.FETCH_APP_LIST_BY_ID_ACCOUNT,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.AccountBase}${API.APPAccountBase}${action.payload.id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* editAccountById(action) {
    yield call(
        request({
            type: DashboardConstants.EDIT_ACCOUNT,
            method: "PUT",
            baseURL: config.BASE_URL,
            url: `${API.AccountBase}/${action.payload?.data?._id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* deleteAccount(action) {
    yield call(
        request({
            type: DashboardConstants.DELETE_ACCOUNT,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.AccountBase}/${action.payload?.id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* deleteRestoreAccount(action) {
    yield call(
        request({
            type: DashboardConstants.DELETE_RESTORE_TRASH_ACCOUNT,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.AccountBase}/${action?.payload?.data?.id}/${action?.payload?.data?.isRestore}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* getTrashListAccount(action) {
    yield call(
        request({
            type: DashboardConstants.GET_ACCOUNT_TRASH_LIST,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.accountTrashBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* deleteRestoreTrashAccount(action) {
    yield call(
        request({
            type: DashboardConstants.DELETE_RESTORE_FROM_TRASH_ACCOUNT,
            method: "PUT",
            baseURL: config.BASE_URL,
            url: `${API.accountTrashBase}${API.accountMultiTrashRestoreBase}${action?.payload?.data?.isDelete}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

export default function* rootSaga() {
    yield takeEvery(DashboardConstants.FETCH_ACCOUNT_LIST, fetchDashboardAccountList);
    yield takeLeading(DashboardConstants.FETCH_ACCOUNT_BY_ID, fetchAccountById);
    yield takeLeading(DashboardConstants.FETCH_APP_LIST_BY_ID_ACCOUNT, fetchAPPByAccountId);
    yield takeLatest(DashboardConstants.CREATE_ACCOUNT, createAccountData);
    yield takeLatest(DashboardConstants.EDIT_ACCOUNT, editAccountById);
    yield takeLatest(DashboardConstants.DELETE_ACCOUNT, deleteAccount);
    yield takeLatest(DashboardConstants.DELETE_RESTORE_TRASH_ACCOUNT, deleteRestoreAccount);
    yield takeLatest(DashboardConstants.DELETE_RESTORE_FROM_TRASH_ACCOUNT, deleteRestoreTrashAccount);
    yield takeLatest(DashboardConstants.GET_ACCOUNT_TRASH_LIST, getTrashListAccount);
}
