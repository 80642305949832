import {handleActions} from "redux-actions";
import {initialUserState} from "./initialUserState";
import {UserConstants} from "./userConstants";
import {
    requestFail, requestPending, requestSuccess
} from "../../../utils/fetch";
import {DashboardConstants} from "../Dashboard/dashboardConstants";

export const userReducer = handleActions({
    [requestSuccess(UserConstants.FETCH_USERS_LIST)]: (state, action) => ({
        ...state,
        fetchUserLoading: false,
        fetchUserFailure: false,
        fetchUserSuccess: true,
        userDataList: (action.payload?.data || [])
    }), [requestPending(UserConstants.FETCH_USERS_LIST)]: state => ({
        ...state,
        fetchUserLoading: true,
        fetchUserFailure: false,
        fetchUserSuccess: false,
        userDataList: []
    }), [requestFail(UserConstants.FETCH_USERS_LIST)]: state => ({
        ...state,
        fetchUserLoading: false,
        fetchUserFailure: true,
        fetchUserSuccess: false,
        userDataList: []
    }),
    [requestSuccess(UserConstants.UPDATE_USER_DATA)]: (state, action) => ({
        ...state,
        updateUserLoading: false,
        updateUserFailure: false,
        updateUserSuccess: true,
        userData: {}
    }), [requestPending(UserConstants.UPDATE_USER_DATA)]: state => ({
        ...state,
        updateUserLoading: true,
        updateUserFailure: false,
        updateUserSuccess: false,
        userData: {}
    }), [requestFail(UserConstants.UPDATE_USER_DATA)]: state => ({
        ...state,
        updateUserLoading: false,
        updateUserFailure: true,
        updateUserSuccess: false,
        userData: {}
    }),
}, initialUserState());
