export const initialDashboardState = () => ({
    fetchDashboardLoading: false,
    fetchDashboardFailure: false,
    fetchDashboardSuccess: false,
    dashboardList: [],
    createAccountLoading: false,
    createAccountFailure: false,
    createAccountSuccess: false,
    fetchAccountByIdLoading: false,
    fetchAccountByIdFailure: false,
    fetchAccountByIdSuccess: false,
    accountById: {},
    editAccountLoading: false,
    editAccountFailure: false,
    editAccountSuccess: false,
    deleteAccountLoading: false,
    deleteAccountFailure: false,
    deleteAccountSuccess: false,
    fetchTrashAccountLoading: false,
    fetchTrashAccountFailure: false,
    fetchTrashAccountSuccess: false,
    trashAccountList: [],
    deleteRestoreAccountLoading: false,
    deleteRestoreAccountFailure: false,
    deleteRestoreAccountSuccess: false,
    deleteRestoreTrashFromAccountLoading: false,
    deleteRestoreTrashFromAccountFailure: false,
    deleteRestoreTrashFromAccountSuccess: false,
    fetchAppByAccountIdLoading: false,
    fetchAppByAccountIdFailure: false,
    fetchAppByAccountIdSuccess: false,
    appListByAccountId: []
});
