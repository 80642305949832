import React, {useEffect, useState} from 'react'
import {Select, Table, Pagination} from 'antd';
import leftArrow from "../../assets/icons/left-arrow.png";
import rightArrow from "../../assets/icons/right-arrow.png";

const {Option} = Select;

const DataTable = props => {

    const {
        data,
        columns,
        setSelectedRowData,
        setSelectedRowIds,
        className,
        rowClassName,
        rowSelection,
        expandedRowRender,
        rowSelectionByMultiple,
        onExpand,
        noPagination,
        fetchData,
        loading,
        expandedRowKeys,
        total,
        scroll,
        removeSelection,
        showCustomPagination,
        hideSelect,
        paginationState,
        setCustomPagination,
        showHeader
    } = props;
    const pageSizeOptions = [2, 10, 20, 50, 100];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRowList, setSelectedRowList] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: total
    });

    useEffect(() => {
        setSelectedRowKeys([]);
        setSelectedRowList([]);
    }, [removeSelection]);

    useEffect(() => {
        setPagination(prevState => ({
            ...prevState,
            current: 1,
            total: total
        }));
        setCustomPagination && setCustomPagination(prevState => ({
            ...prevState,
            current: 1,
            total: total
        }));
    }, [total]);

    const onPaginationSettings = (key, value) => {
        setPagination(prevState => ({
            ...prevState,
            [key]: value
        }));
        setCustomPagination && setCustomPagination(prevState => ({
            ...prevState,
            [key]: value
        }));

        let obj = {page: pagination.current, pageSize: pagination.pageSize};
        if (key === "current") obj.page = value;
        if (key === "pageSize") {
            obj.page = 1;
            obj.pageSize = value;
            setPagination(prevState => ({
                ...prevState,
                page: 1,
                current: 1
            }));
            setCustomPagination && setCustomPagination(prevState => ({
                ...prevState,
                page: 1,
                current: 1
            }));
        }
        fetchData && fetchData(obj);
    };

    const paginationProps = {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
        responsive: true,
        itemRender: (current, type, originalElement) => {
            if (type === 'prev') {
                return <span><img src={leftArrow} alt="left"/></span>;
            }
            if (type === 'next') {
                return <span><img src={rightArrow} alt="right"/></span>;
            }
            return originalElement;
        },
        onChange: (pageNumber) => onPaginationSettings('current', pageNumber)
    };

    const renderItemsString = (isNaN(pagination.current) && isNaN(pagination.pageSize) && isNaN(pagination.total)) ? '0 - 0 items of 0' : `${paginationProps.current > 1 ?
        (pagination.total > pagination.current * pagination.pageSize) ?
            ((paginationProps.current - 1) * paginationProps.pageSize) + 1 : pagination.total > pagination.pageSize && pagination.pageSize + 1 || 1 : 1}
      -${paginationProps.total > ((paginationProps.current - 1) * paginationProps.pageSize) + (paginationProps.pageSize)
        ? ((paginationProps.current - 1) * paginationProps.pageSize) + (paginationProps.pageSize)
        : paginationProps.total} items of ${paginationProps.total || ""}`;

    const SetRowSelection = (selectedRowKeysList, selectedRows) => {
        setSelectedRowList(selectedRows);
        setSelectedRowKeys(selectedRowKeysList);
        setSelectedRowData && setSelectedRowData(selectedRows);
        setSelectedRowIds && setSelectedRowIds(selectedRowKeysList);
    };

    return (
        <div className="data-table mt-3">
            <Table
                showHeader={showHeader}
                className={className}
                rowClassName={rowClassName}
                loading={loading}
                rowSelection={rowSelectionByMultiple ? {
                    type: "checkbox",
                    selectedRowKeys,
                    selectedRowList,
                    preserveSelectedRowKeys: false,
                    onChange: (selectedRowKeys, selectedRows) => SetRowSelection(selectedRowKeys, selectedRows),
                    // onSelect: (record, selected, selectedRows) => SetRowSelection(selectedRows?.map((i) => i?.key), record),
                    // onSelectAll: (selected, selectedRows, changeRows) => SetRowSelection(selectedRows?.filter((i) => i?.key), changeRows),
                    getCheckboxProps: (record) => ({
                        name: record.name
                    }),
                } : rowSelection}
                columns={columns}
                dataSource={data}
                scroll={scroll}
                // expandable={{
                //   expandedRowRender:expandedRowRender,
                //   onExpand:onExpand,
                //   expandedRowKeys:expandedRowKeys
                // }}
                rowKey="_id"
                pagination={noPagination ? false : !showCustomPagination ? {
                    current: paginationProps.current,
                    pageSize: paginationProps.pageSize,
                    total: paginationProps.total,
                } : false}
            />
            {!noPagination &&
            <div className="custom-pagination mt-2">
                {!hideSelect && <div className="">
                    View{" "}
                    <Select value={paginationProps?.pageSize} style={{width: 70}}
                            onChange={value => onPaginationSettings('pageSize', parseInt(value))}>
                        {(pageSizeOptions || []).map((opt, key) => <Option key={key} value={opt}>{opt}</Option>)}
                    </Select>{" "}
                    Items per page
                </div>}
                <div className="mr-5">
                    {renderItemsString}
                </div>
                <Pagination {...paginationProps} />
            </div>}
        </div>
    )
};

export default React.memo(DataTable)
