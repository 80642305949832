const ConsoleImg = () => {

    return (<svg width="24px" height="24px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <style>{`.a{fill:none;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;}`}</style>
        </defs>
        <path class="a"
              d="M19.1835,9.8732,10.2422,4.711A1.5576,1.5576,0,0,0,7.9058,6.06V41.94a1.5576,1.5576,0,0,0,2.3364,1.3489l31.0732-17.94a1.5576,1.5576,0,0,0,0-2.6978l-2.7412-1.5826"/>
        <path class="a" d="M25.9133,34.2413H14.3663a1.5575,1.5575,0,0,1-1.5576-1.5576V9.8732H38.5742V26.9315"/>
        <line class="a" x1="12.8087" y1="15.2447" x2="38.5742" y2="15.2447"/>
        <circle class="a" cx="18.5295" cy="26.8173" r="1.5576"/>
        <circle class="a" cx="23.7875" cy="21.5593" r="1.5576"/>
        <circle class="a" cx="27.5264" cy="25.2982" r="1.5576"/>
        <circle class="a" cx="33.1397" cy="19.685" r="1.5576"/>
        <line class="a" x1="32.0383" y1="20.7864" x2="28.6278" y2="24.1968"/>
        <line class="a" x1="24.8889" y1="22.6607" x2="26.4251" y2="24.1968"/>
        <line class="a" x1="19.6309" y1="25.716" x2="22.6862" y2="22.6607"/>
    </svg>)
};
export default ConsoleImg;