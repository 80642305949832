import React, {useEffect, useState} from 'react';
import {CloseOutlined, MenuOutlined} from "@ant-design/icons";
import {NavLink, useLocation} from "react-router-dom";
import {Menu, MenuItem, Sidebar, SubMenu, useProSidebar} from "react-pro-sidebar";
import {useSelector} from "react-redux";

import sidenav from './_sidenav';
import {Avtar} from "../../reusable";
//SASS
import "./TheSidebar.scss";
import {getCookie, getFromStorage} from "../../utils/common";

const TheSidebar = (props) => {
    const initialState = ['email', 'name', 'image', '_id'];
    const location = useLocation();
    const [selected, setSelected] = useState("");
    const {users} = useSelector(state => state);
    let localStorageData = {};
    let cookieData = {};

    useEffect(() => {
        setSelected(sidenav.find((item) => item.to.includes(location?.pathname))?.name);
    }, []);

    initialState.map((item) => {
        localStorageData = {...localStorageData, [item]: getFromStorage(item)}
    });

    initialState.map((item) => {
        cookieData = {...cookieData, [item]: getCookie(item)}
    });

    const data = useSelector(state => state?.authorization)?.userDetail;
    const userData = Object.keys(data).length ? data : localStorageData || cookieData;
    const {collapseSidebar, toggleSidebar, collapsed, broken} = useProSidebar();

    const Item = ({title, to, icon, selected, setSelected, child}) => {
        return (
            <>
                {child?.length > 0 ?
                    <SubMenu label={title} icon={icon}
                        className={selected === title ? 'ps-active' : ''}
                        active={selected === title}
                        style={{color: '#565656'}}
                    >
                        {child?.map((item,index) =>
                            <MenuItem
                            key={index}
                                icon={item?.icon}
                                onClick={() => setSelected(title)}
                                component={<NavLink to={item?.to}/>}> {item?.name}</MenuItem>
                        )}
                    </SubMenu>
                    :
                    <MenuItem
                        className={selected === title ? 'ps-active' : ''}
                        active={selected === title}
                        style={{color: '#565656'}}
                        onClick={() => setSelected(title)}
                        icon={icon}
                        component={<NavLink to={to}/>}
                    >
                        <p className='mb-0'>{title}</p>
                    </MenuItem>
                }
            </>
        )
    };

    return (
        <>
            <Sidebar
                breakPoint="md"
                className='zIndex-plus-40 main-sidebar'
                backgroundColor='#fff'
            >
                <Menu transitionDuration={500}>
                    <MenuItem
                        icon={
                            collapsed &&
                            <MenuOutlined className='hover-transparent  p-2' onClick={() => collapseSidebar()}/>
                        }
                        style={{
                            margin: "10px 0 20px 0",
                            color: '#a3a3a3',
                        }}
                        className='text-dark sidebar-header'
                    >
                        {!collapsed && (
                            <div className='d-inline-flex align-items-center w-100 justify-content-between'>
                                ADMINS
                                <CloseOutlined className='hover-transparent rounded-circle p-2' onClick={
                                    broken ? () => toggleSidebar() : () => collapseSidebar()
                                }/>
                            </div>
                        )}
                    </MenuItem>
                    {!collapsed && (
                        <div className='d-flex flex-column align-items-center'>
                            <Avtar size={100} userName={userData?.name}
                                   imgUrl={users?.userData?.image || userData?.image}/>
                            <p className='my-2 text-gray'>
                                {userData?.name}
                            </p>
                        </div>
                    )}
                    <div>
                        {sidenav?.map((item, index) => <Item
                            key={index}
                            title={item?.name}
                            to={item?.to}
                            icon={item?.icon}
                            selected={selected}
                            setSelected={setSelected}
                            child={item?.child}
                        />)}
                    </div>
                </Menu>
            </Sidebar>
        </>
    )
};

export default React.memo(TheSidebar)
