import {call, takeLatest} from "redux-saga/effects";
//
import {request} from "../../../utils/fetch";
//
import {AddExtraConstants} from "./addExtraConstants";

// APIS
import {config} from "../../../utils/config";
import * as API from "../../../utils/apiConsts";

function* fetchAllExtraField(action) {
    yield call(
        request({
            type: AddExtraConstants.FETCH_ALL_EXTRA_FIELD,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.ExtraFieldBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}
function* fetchExtraField(action) {
    yield call(
        request({
            type: AddExtraConstants.FETCH_EXTRA_FIELD,
            method: "PATCH",
            baseURL: config.BASE_URL,
            url: `${API.ExtraFieldBase}/get?page=${action.payload.data?.page}&limit=${action.payload.data?.pageSize}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* createExtraField(action) {
    yield call(
        request({
            type: AddExtraConstants.CREATE_EXTRA_FIELD,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.ExtraFieldBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* editExtraField(action) {
    yield call(
        request({
            type: AddExtraConstants.EDIT_EXTRA_FIELD,
            method: "PUT",
            baseURL: config.BASE_URL,
            url: `${API.ExtraFieldBase}/${action.payload.data._id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* deleteExtraField(action) {
    yield call(
        request({
            type: AddExtraConstants.DELETE_EXTRA_FIELD,
            method: "DELETE",
            baseURL: config.BASE_URL,
            url: `${API.ExtraFieldBase}/${action.payload.data._id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* editMultiExtraField(action) {
    yield call(
        request({
            type: AddExtraConstants.EDIT_EXTRA_FIELD,
            method: "PUT",
            baseURL: config.BASE_URL,
            url: `${API.ExtraFieldBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

export default function* rootSaga() {
    yield takeLatest(AddExtraConstants.FETCH_EXTRA_FIELD, fetchExtraField);
    yield takeLatest(AddExtraConstants.FETCH_ALL_EXTRA_FIELD, fetchAllExtraField);
    yield takeLatest(AddExtraConstants.CREATE_EXTRA_FIELD, createExtraField);
    yield takeLatest(AddExtraConstants.EDIT_EXTRA_FIELD, editExtraField);
    yield takeLatest(AddExtraConstants.DELETE_EXTRA_FIELD, deleteExtraField);
    yield takeLatest(AddExtraConstants.EDIT_MULTI_EXTRA_FIELD, editMultiExtraField);
}
