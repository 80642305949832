import {call, takeLeading} from "redux-saga/effects";
//
import {request} from "../../../utils/fetch";
//
import {TestKeysConstants} from "./testKeysConstants";

// APIS
import {config} from "../../../utils/config";
import * as API from "../../../utils/apiConsts";

function* fetchTestKeysDataList(action) {
    yield call(
        request({
            type: TestKeysConstants.FETCH_TEST_KEYS_LIST,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.TestSSHKeyBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* createTestKeys(action) {
    yield call(
        request({
            type: TestKeysConstants.CREATE_TEST_KEYS_LIST,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.TestSSHKeyBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* editTestKeys(action) {
    yield call(
        request({
            type: TestKeysConstants.EDIT_TEST_KEYS_LIST,
            method: "PUT",
            baseURL: config.BASE_URL,
            url: `${API.TestSSHKeyBase}/${action.payload.data._id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* fetchTestKeysById(action) {
    yield call(
        request({
            type: TestKeysConstants.FETCH_BY_ID_TEST_KEYS_LIST,
            method: "PUT",
            baseURL: config.BASE_URL,
            url: `${API.TestSSHKeyBase}/${action.payload.id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* deleteTestKeys(action) {
    yield call(
        request({
            type: TestKeysConstants.DELETE_TEST_KEYS,
            method: "DELETE",
            baseURL: config.BASE_URL,
            url: `${API.TestSSHKeyBase}/${action?.payload?.data?._id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

export default function* rootSaga() {
    yield takeLeading(TestKeysConstants.FETCH_TEST_KEYS_LIST, fetchTestKeysDataList);
    yield takeLeading(TestKeysConstants.CREATE_TEST_KEYS_LIST, createTestKeys);
    yield takeLeading(TestKeysConstants.EDIT_TEST_KEYS_LIST, editTestKeys);
    yield takeLeading(TestKeysConstants.FETCH_BY_ID_TEST_KEYS_LIST, fetchTestKeysById);
    yield takeLeading(TestKeysConstants.DELETE_TEST_KEYS, deleteTestKeys);
}
