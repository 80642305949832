import React from 'react';
import {Tooltip} from "antd";
import {EnvironmentOutlined, HomeOutlined, SettingOutlined} from "@ant-design/icons";

import {
    AccountList,
    AppIcon, barGraph,
    CategoryIcon, ConsoleImg,
    PlatformIcon,
    ProfileIcon,
    settingProfile,
    UserListIcon,
    KeyIcon
} from "../../assets/sidebarImg";

import './TheSidebar.scss'

const side_Nav = [
    {
        _tag: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/',
        icon: <HomeOutlined/>,
        icon1: <Tooltip title={'Dashboard'} placement="right"><img src={barGraph} className="parent-nav-icon"
                                                                   alt=""/></Tooltip>,
    },
    {
        _tag: 'CSidebarNavItem',
        name: 'Location Wise Report',
        to: '/all-report',
        icon: <EnvironmentOutlined/>,
        icon1: <Tooltip title={'Location Wise Report'} placement="right"><img src={barGraph} className="parent-nav-icon"
                                                                              alt=""/></Tooltip>,
    },
    {
        _tag: 'CSidebarNavItem',
        name: 'All Apps',
        to: '/all-apps',
        icon: <AppIcon/>,
        icon1: <Tooltip title={'Application'} placement="right"><img src={settingProfile} className="parent-nav-icon"
                                                                     alt=""/></Tooltip>,
    },
    {
        _tag: 'CSidebarNavItem',
        name: 'Account',
        to: '/account',
        icon: <AccountList/>,
        icon1: <Tooltip title={'Account'} placement="right"><img src={settingProfile} className="parent-nav-icon"
                                                                 alt=""/></Tooltip>,
    },
    {
        _tag: 'CSidebarNavItem',
        name: 'Platform',
        to: '/platform',
        icon: <PlatformIcon/>,
        icon1: <Tooltip title={'Platform'} placement="right"><img src={settingProfile} className="parent-nav-icon"
                                                                  alt=""/></Tooltip>,
    },
    {
        _tag: 'CSidebarNavItem',
        name: 'Category',
        to: '/category',
        icon: <CategoryIcon/>,
        icon1: <Tooltip title={'Platform'} placement="right"><img src={settingProfile} className="parent-nav-icon"
                                                                  alt=""/></Tooltip>,
    },
    {
        _tag: 'CSidebarNavItem',
        name: 'Profile',
        to: '/profile',
        icon: <ProfileIcon/>,
        icon1: <Tooltip title={'Platform'} placement="right"><img src={settingProfile} className="parent-nav-icon"
                                                                  alt=""/></Tooltip>,
    },
    {
        _tag: 'CSidebarNavItem',
        name: 'User List',
        to: '/user-list',
        icon: <UserListIcon/>,
        icon1: <Tooltip title={'User List'} placement="right"><img src={settingProfile} className="parent-nav-icon"
                                                                   alt=""/></Tooltip>,
    },
    {
        _tag: 'CSidebarNavItem',
        name: 'Settings',
        to: ['/settings', '/all-console', '/extra-fields', '/test-keys'],
        icon: <SettingOutlined/>,
        child: [
            {
                _tag: 'CSidebarNavItem',
                name: 'All ConsoleList',
                to: '/all-console',
                icon: <ConsoleImg/>
            },
            {
                _tag: 'CSidebarNavItem',
                name: 'Test Keys',
                to: '/test-keys',
                icon: <KeyIcon/>
            },
            {
                _tag: 'CSidebarNavItem',
                name: 'App Common Field',
                to: '/extra-fields',
                icon: <SettingOutlined/>
            }
        ]
    },
];


export default side_Nav
