import {handleActions} from "redux-actions";
import {initialAddExtraState} from "./initialAddExtraState";
import {AddExtraConstants} from "./addExtraConstants";
import {
    requestFail, requestPending, requestSuccess
} from "../../../utils/fetch";

export const addExtraReducer = handleActions({
    [requestSuccess(AddExtraConstants.FETCH_EXTRA_FIELD)]: (state, action) => ({
        ...state,
        extraFieldLoading: false,
        extraFieldFailure: false,
        extraFieldSuccess: true,
        extraFieldList: action.payload?.data || []
    }), [requestPending(AddExtraConstants.FETCH_EXTRA_FIELD)]: state => ({
        ...state,
        extraFieldLoading: true,
        extraFieldFailure: false,
        extraFieldSuccess: false,
        extraFieldList: []
    }), [requestFail(AddExtraConstants.FETCH_EXTRA_FIELD)]: state => ({
        ...state,
        extraFieldLoading: false,
        extraFieldFailure: true,
        extraFieldSuccess: false,
        extraFieldList: []
    }),
    [requestSuccess(AddExtraConstants.FETCH_ALL_EXTRA_FIELD)]: (state, action) => ({
        ...state,
        extraAllFieldLoading: false,
        extraAllFieldFailure: false,
        extraAllFieldSuccess: true,
        extraAllFieldList: action.payload?.data || []
    }), [requestPending(AddExtraConstants.FETCH_ALL_EXTRA_FIELD)]: state => ({
        ...state,
        extraAllFieldLoading: true,
        extraAllFieldFailure: false,
        extraAllFieldSuccess: false,
        extraAllFieldList: []
    }), [requestFail(AddExtraConstants.FETCH_ALL_EXTRA_FIELD)]: state => ({
        ...state,
        extraAllFieldLoading: false,
        extraAllFieldFailure: true,
        extraAllFieldSuccess: false,
        extraAllFieldList: []
    }),
    [requestSuccess(AddExtraConstants.CREATE_EXTRA_FIELD)]: (state, action) => ({
        ...state,
        createFieldLoading: false,
        createFieldFailure: false,
        createFieldSuccess: true,
    }), [requestPending(AddExtraConstants.CREATE_EXTRA_FIELD)]: state => ({
        ...state,
        createFieldLoading: true,
        createFieldFailure: false,
        createFieldSuccess: false,
    }), [requestFail(AddExtraConstants.CREATE_EXTRA_FIELD)]: state => ({
        ...state,
        createFieldLoading: false,
        createFieldFailure: true,
        createFieldSuccess: false,
    }),
    [requestSuccess(AddExtraConstants.EDIT_EXTRA_FIELD)]: (state, action) => ({
        ...state,
        editFieldLoading: false,
        editFieldFailure: false,
        editFieldSuccess: true,
    }), [requestPending(AddExtraConstants.EDIT_EXTRA_FIELD)]: state => ({
        ...state,
        editFieldLoading: true,
        editFieldFailure: false,
        editFieldSuccess: false,
    }), [requestFail(AddExtraConstants.EDIT_EXTRA_FIELD)]: state => ({
        ...state,
        editFieldLoading: false,
        editFieldFailure: true,
        editFieldSuccess: false,
    }),
    [requestSuccess(AddExtraConstants.DELETE_EXTRA_FIELD)]: (state, action) => ({
        ...state,
        deleteFieldLoading: false,
        deleteFieldFailure: false,
        deleteFieldSuccess: true,
    }), [requestPending(AddExtraConstants.DELETE_EXTRA_FIELD)]: state => ({
        ...state,
        deleteFieldLoading: true,
        deleteFieldFailure: false,
        deleteFieldSuccess: false,
    }), [requestFail(AddExtraConstants.DELETE_EXTRA_FIELD)]: state => ({
        ...state,
        deleteFieldLoading: false,
        deleteFieldFailure: true,
        deleteFieldSuccess: false,
    }),
    [requestSuccess(AddExtraConstants.EDIT_MULTI_EXTRA_FIELD)]: (state, action) => ({
        ...state,
        editMultiFieldLoading: false,
        editMultiFieldFailure: false,
        editMultiFieldSuccess: true,
    }), [requestPending(AddExtraConstants.EDIT_MULTI_EXTRA_FIELD)]: state => ({
        ...state,
        editMultiFieldLoading: true,
        editMultiFieldFailure: false,
        editMultiFieldSuccess: false,
    }), [requestFail(AddExtraConstants.EDIT_MULTI_EXTRA_FIELD)]: state => ({
        ...state,
        editMultiFieldLoading: false,
        editMultiFieldFailure: true,
        editMultiFieldSuccess: false,
    }),
}, initialAddExtraState());
