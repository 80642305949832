import {Tabs} from 'antd';

const AntTabBar = (props) => {
    const {onChange, items} = props;
    return (<Tabs
        onChange={onChange}
        type="card"
        items={(items || [])?.map((item, idx) => ({
            label: item?.label,
            key: idx,
            children: item?.children
        }))}
    />)
};
export default AntTabBar;