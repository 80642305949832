import React from "react";
import Chart from 'react-apexcharts';

const MixedChart = (props) => {
    const {title, labels, xAxisTitle, yAxisTitle, series} = props;
    const maxYAxis = (series?.length > 0 && series[0].data)  ? (Math.max(...series[0].data) + 10) : 'auto';

    const data = {
        series: series,
        options: {
            chart: {
                height: 350,
                type: 'line',
            },
            stroke: {
                width: [0, 4]
            },
            title: {
                text: title
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1, 2]
            },
            labels: labels,
            xaxis: {
                title: {
                    text: xAxisTitle,
                },
            },
            yaxis: [{
                min: 0,
                // logBase: 2,
                tickAmount : maxYAxis / 10,
                max: maxYAxis,
                title: {
                    text: yAxisTitle,
                },

            }],
            tooltip: {
                x: {
                    show: false
                },
                y: {
                    formatter: function (value, series) {
                        return value
                    }
                }
            },
            noData: {
                text: "No Download data found for this APP",
                align: "center",
                verticalAlign: "middle",
            }
        },
    };

    return (
        <Chart
            options={data.options}
            series={data.series}
            type="line"
            height="350"
        />
    )

}
export default MixedChart;
