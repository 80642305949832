const UserListIcon = () => {
    return (
        <svg fill="#000000" height="24px" width="24px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 198.145 198.145">
<path d="M195.536,68.18c-1.895-2.079-4.578-3.265-7.392-3.265H10c-2.813,0-5.497,1.186-7.392,3.265s-2.826,4.861-2.565,7.662
	l9.505,102.099c0.479,5.142,4.793,9.073,9.957,9.073h159.134c5.164,0,9.478-3.932,9.957-9.073l9.505-102.099
	C198.362,73.041,197.431,70.259,195.536,68.18z M132.426,166.073c-0.007-0.001-0.013-0.001-0.02,0H65.739c-5.523,0-10-4.478-10-10
	c0-13.58,11.048-24.628,24.628-24.628h7.177c-7.381-4.078-12.393-11.94-12.393-20.952c0-13.19,10.731-23.922,23.922-23.922
	s23.922,10.731,23.922,23.922c0,9.012-5.012,16.874-12.393,20.952h7.177c13.303,0,24.176,10.603,24.614,23.802
	c0.022,0.272,0.034,0.548,0.034,0.826C142.426,161.596,137.949,166.073,132.426,166.073z M178.906,46.298c0,4.143-3.358,7.5-7.5,7.5
	H26.739c-4.142,0-7.5-3.357-7.5-7.5s3.358-7.5,7.5-7.5h144.667C175.548,38.798,178.906,42.155,178.906,46.298z M159.106,18.631
	c0,4.143-3.358,7.5-7.5,7.5H46.539c-4.142,0-7.5-3.357-7.5-7.5s3.358-7.5,7.5-7.5h105.066
	C155.748,11.131,159.106,14.488,159.106,18.631z"/>
</svg>
    )
};
export default UserListIcon;
