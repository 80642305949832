import React, {useEffect} from 'react';
import {Select} from 'antd';
import {downArrowIcon} from "../../assets/icons";
import "./SelectMenu.scss"

const SelectMenu = props => {
    const {
        label,
        onBlur,
        name,
        labelIndex,
        valueIndex,
        getValueNoId,
        value,
        findOption,
        onChange,
        placeholder,
        defaultValue,
        options,
        parentClass,
        labelClass,
        inputClass,
        mb0,
        error,
        disabled,
        required,
        errorMsg,
        withSearch
    } = props;
    const [blur, setBlur] = React.useState(false);
    const [customizeOptions, setCustomizeOptions] = React.useState([]);

    useEffect(() => {
        if (withSearch) {
            const data = options?.map((item) => ({value: valueIndex ? item[valueIndex] : item?._id, label: item[labelIndex]}));
            setCustomizeOptions(data);
        }
    }, [options]);

    function errorStatus() {
        return required ? error ? true : (blur && !value) : false
    }

    const handleOnBlur = () => {
        setBlur(true);
        if (onBlur)
            onBlur()
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };

    return (
        <>{withSearch ?
            <div className={`d-flex flex-column ${parentClass}`}>
                {label && <label className={`labelClass margin-left-5 ${labelClass}`}>{label} {required &&
                <span className="color-red">*</span>}</label>}
                <Select
                    showSearch
                    placeholder={placeholder}
                    optionFilterProp="children"
                    onChange={onChange}
                    value={value}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={customizeOptions}
                />
            </div>
            :
            label ? <div className={`selectMenu mt-20 ${parentClass || ""}`}>
                    <div className={`form-group set-width ${mb0}`}>
                        {label && <label className={`labelClass margin-left-5 ${labelClass}`}>{label} {required &&
                        <span className="color-red">*</span>}</label>}
                        <div className="position-relative">
                            <span className="down-arrow"><img src={downArrowIcon} alt="arrow"/></span>
                            <select
                                disabled={disabled}
                                value={value || value === 0 ? value : ""}
                                className={`form-control inputClass  ${inputClass || "w-100"} ${errorStatus() && 'error'} ${(!value || value === '') && 'text-gray'}`}
                                name={name}
                                onChange={onChange}
                                onBlur={handleOnBlur}
                            >
                                {placeholder && <option className="text-muted" value="">{placeholder}</option>}
                                {findOption ?
                                    options?.length > 0 && options.map((option, index) => {
                                        return (
                                            <option key={index} disabled={findOption[index] && findOption[index]}
                                                    value={option.value || option.id || option.value}>{option?.name || option?.label || [option[labelIndex]] || index}</option>
                                        )
                                    })
                                    :
                                    options?.length > 0 && options.map((option, index) => {
                                        return (
                                            <option key={index}
                                                    value={option.value || option.id || option.value || (getValueNoId ? [option[labelIndex]] : option._id)}>{option?.name || option?.label || [option[labelIndex]] || index}</option>
                                        )
                                    })}
                            </select>
                            {errorStatus() &&
                            <span className="margin-left-5 error-label">{errorMsg || `${name} is required`}</span>}
                        </div>
                    </div>
                </div>
                :
                <div className={parentClass}>
                    <select
                        defaultValue={defaultValue}
                        value={value || value === 0 ? value : ""}
                        name={name}
                        onChange={onChange}
                        onBlur={handleOnBlur}
                        className={`form-control inputClass  ${inputClass || "w-100"} ${error && 'error'}`}
                        disabled={disabled}
                    >
                        {placeholder && <option className="text-muted" value="">{placeholder}</option>}
                        {options.length > 0 && options.map((option, index) => {
                            return (
                                <option key={index}
                                        value={option.value || option.id}>{option?.name || option?.label || [option[labelIndex]] || index || option.value}</option>
                            )
                        })}
                    </select>
                </div>
        }
        </>
    )
};

export default SelectMenu
