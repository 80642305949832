import React from 'react';
import {Switch as AntdSwitch} from 'antd';
import "./Switch.scss";

const Switch = props => {
    const {name, checked, onChange, disabled, label, subLabel, size, customClass, labelClass, className} = props;
    return (
        <div className={`switch mt-20 ${customClass}`}>
            {label && <label className={`labelClass ${labelClass}`}>{label}</label>}
            <div>
                <AntdSwitch disabled={disabled} size={size} checked={checked} name={name} className={className}
                            onChange={onChange}/>
            </div>
            {subLabel && <label className="labelClass">{subLabel}</label>}
        </div>
    )
};

export default Switch
