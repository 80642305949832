import {handleActions} from "redux-actions";
import {initialOtherKeysAccessState} from "./initialOtherKeysAccessState";
import {OtherKeysAccessConstants} from "./otherKeysAccessConstants";
import {
    requestFail, requestPending, requestSuccess
} from "../../../utils/fetch";

export const otherKeysAccessReducer = handleActions({
    [requestSuccess(OtherKeysAccessConstants.FETCH_OTHER_KEYS_LIST)]: (state, action) => ({
        ...state,
        fetchOtherKeysAccessLoading: false,
        fetchOtherKeysAccessFailure: false,
        fetchOtherKeysAccessSuccess: true,
        otherKeysAccessDataList: (action.payload?.data || [])
    }), [requestPending(OtherKeysAccessConstants.FETCH_OTHER_KEYS_LIST)]: state => ({
        ...state,
        fetchOtherKeysAccessLoading: true,
        fetchOtherKeysAccessFailure: false,
        fetchOtherKeysAccessSuccess: false,
        otherKeysAccessDataList: []
    }), [requestFail(OtherKeysAccessConstants.FETCH_OTHER_KEYS_LIST)]: state => ({
        ...state,
        fetchOtherKeysAccessLoading: false,
        fetchOtherKeysAccessFailure: true,
        fetchOtherKeysAccessSuccess: false,
        otherKeysAccessDataList: []
    }),
    [requestSuccess(OtherKeysAccessConstants.CREATE_OTHER_KEYS_LIST)]: (state, action) => ({
        ...state,
        createOtherKeysAccessLoading: false,
        createOtherKeysAccessFailure: false,
        createOtherKeysAccessSuccess: true,
    }), [requestPending(OtherKeysAccessConstants.CREATE_OTHER_KEYS_LIST)]: state => ({
        ...state,
        createOtherKeysAccessLoading: true,
        createOtherKeysAccessFailure: false,
        createOtherKeysAccessSuccess: false,
    }), [requestFail(OtherKeysAccessConstants.CREATE_OTHER_KEYS_LIST)]: state => ({
        ...state,
        createOtherKeysAccessLoading: false,
        createOtherKeysAccessFailure: true,
        createOtherKeysAccessSuccess: false,
    }),
    [requestSuccess(OtherKeysAccessConstants.EDIT_OTHER_KEYS_LIST)]: (state, action) => ({
        ...state,
        editOtherKeysAccessLoading: false,
        editOtherKeysAccessFailure: false,
        editOtherKeysAccessSuccess: true,
    }), [requestPending(OtherKeysAccessConstants.EDIT_OTHER_KEYS_LIST)]: state => ({
        ...state,
        editOtherKeysAccessLoading: true,
        editOtherKeysAccessFailure: false,
        editOtherKeysAccessSuccess: false,
    }), [requestFail(OtherKeysAccessConstants.EDIT_OTHER_KEYS_LIST)]: state => ({
        ...state,
        editOtherKeysAccessLoading: false,
        editOtherKeysAccessFailure: true,
        editOtherKeysAccessSuccess: false,
    }),
    [requestSuccess(OtherKeysAccessConstants.FETCH_BY_ID_OTHER_KEYS_LIST)]: (state, action) => ({
        ...state,
        fetchByIdOtherKeysAccessLoading: false,
        fetchByIdOtherKeysAccessFailure: false,
        fetchByIdOtherKeysAccessSuccess: true,
        otherKeysAccessDataById: action?.payload?.data || {}
    }), [requestPending(OtherKeysAccessConstants.FETCH_BY_ID_OTHER_KEYS_LIST)]: state => ({
        ...state,
        fetchByIdOtherKeysAccessLoading: true,
        fetchByIdOtherKeysAccessFailure: false,
        fetchByIdOtherKeysAccessSuccess: false,
        otherKeysAccessDataById: {}
    }), [requestFail(OtherKeysAccessConstants.FETCH_BY_ID_OTHER_KEYS_LIST)]: state => ({
        ...state,
        fetchByIdOtherKeysAccessLoading: false,
        fetchByIdOtherKeysAccessFailure: true,
        fetchByIdOtherKeysAccessSuccess: false,
        otherKeysAccessDataById: {}
    }),
    [requestSuccess(OtherKeysAccessConstants.DELETE_OTHER_KEYS)]: (state, action) => ({
        ...state,
        deleteOtherKeysAccessLoading: false,
        deleteOtherKeysAccessFailure: false,
        deleteOtherKeysAccessSuccess: true,
    }), [requestPending(OtherKeysAccessConstants.DELETE_OTHER_KEYS)]: state => ({
        ...state,
        deleteOtherKeysAccessLoading: true,
        deleteOtherKeysAccessFailure: false,
        deleteOtherKeysAccessSuccess: false,
    }), [requestFail(OtherKeysAccessConstants.DELETE_OTHER_KEYS)]: state => ({
        ...state,
        deleteOtherKeysAccessLoading: false,
        deleteOtherKeysAccessFailure: true,
        deleteOtherKeysAccessSuccess: false,
    }),
    [requestSuccess(OtherKeysAccessConstants.ASSIGN_KEYS_TO_APP)]: (state, action) => ({
        ...state,
        assignKeyToAppLoading: false,
        assignKeyToAppFailure: false,
        assignKeyToAppSuccess: true,
    }), [requestPending(OtherKeysAccessConstants.ASSIGN_KEYS_TO_APP)]: state => ({
        ...state,
        assignKeyToAppLoading: true,
        assignKeyToAppFailure: false,
        assignKeyToAppSuccess: false,
    }), [requestFail(OtherKeysAccessConstants.ASSIGN_KEYS_TO_APP)]: state => ({
        ...state,
        assignKeyToAppLoading: false,
        assignKeyToAppFailure: true,
        assignKeyToAppSuccess: false,
    }),
    [requestSuccess(OtherKeysAccessConstants.DELETE_ALL_KEYS)]: (state, action) => ({
        ...state,
        deleteAllTheKeysLoading: false,
        deleteAllTheKeysFailure: false,
        deleteAllTheKeysSuccess: true,
    }), [requestPending(OtherKeysAccessConstants.DELETE_ALL_KEYS)]: state => ({
        ...state,
        deleteAllTheKeysLoading: true,
        deleteAllTheKeysFailure: false,
        deleteAllTheKeysSuccess: false,
    }), [requestFail(OtherKeysAccessConstants.DELETE_ALL_KEYS)]: state => ({
        ...state,
        deleteAllTheKeysLoading: false,
        deleteAllTheKeysFailure: true,
        deleteAllTheKeysSuccess: false,
    }),
}, initialOtherKeysAccessState());
