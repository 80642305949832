export const initialAuthState = () => ({
    fetchCategoryLoading: false,
    fetchCategoryFailure: false,
    fetchCategoryLoaded: false,
    categoryList: [],
    createCategoryLoading: false,
    createCategoryFailure: false,
    createCategoryLoaded: false,
    editCategoryLoading: false,
    editCategoryFailure: false,
    editCategoryLoaded: false,
    deleteCategoryLoading: false,
    deleteCategoryFailure: false,
    deleteCategoryLoaded: false
});
