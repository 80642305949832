import {call, takeLeading, takeLatest} from "redux-saga/effects";
//
import {request} from "../../../utils/fetch";

//Constants
import {PlatformConstants} from "./platformConstants";

// APIS
import * as API from "../../../utils/apiConsts";
import {config} from "../../../utils/config";

function* fetchPlatformList(action) {
    yield call(
        request({
            type: PlatformConstants.FETCH_PLATFORM_LIST,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.PlatformBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* createPlatform(action) {
    yield call(
        request({
            type: PlatformConstants.CREATE_PLATFORM,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.PlatformBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* getPlatformByID(action) {
    yield call(
        request({
            type: PlatformConstants.FETCH_PLATFORM_BY_ID,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.PlatformBase}/${action.payload.id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* editPlatform(action) {
    yield call(
        request({
            type: PlatformConstants.EDIT_PLATFORM,
            method: "PUT",
            baseURL: config.BASE_URL,
            url: `${API.PlatformBase}/${action.payload.data._id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* fetchPlatformTrashList(action) {
    yield call(
        request({
            type: PlatformConstants.PLATFORM_TRASH_LIST,
            method: "GET",
            baseURL: config.BASE_URL,
            url: `${API.platformTrashBase}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* addPlatformToTrashList(action) {
    yield call(
        request({
            type: PlatformConstants.CREATE_PLATFORM,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.PlatformBase}/${action?.payload?.id}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

function* restoreOrRemovePlatformFromTrashList(action) {
    yield call(
        request({
            type: PlatformConstants.DELETE_RESTORE_TRASH_PLATFORM,
            method: "POST",
            baseURL: config.BASE_URL,
            url: `${API.PlatformBase}/${action?.payload?.data?.id}/${action?.payload?.data?.isRestore}`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
        }),
        action
    );
}

export default function* rootSaga() {
    yield takeLeading(PlatformConstants.FETCH_PLATFORM_LIST, fetchPlatformList);
    yield takeLeading(PlatformConstants.FETCH_PLATFORM_BY_ID, getPlatformByID);
    yield takeLatest(PlatformConstants.CREATE_PLATFORM, createPlatform);
    yield takeLatest(PlatformConstants.EDIT_PLATFORM, editPlatform);
    yield takeLatest(PlatformConstants.PLATFORM_TRASH_LIST, fetchPlatformTrashList);
    yield takeLatest(PlatformConstants.ADD_PLATFORM_TO_TRASH, addPlatformToTrashList);
    yield takeLatest(PlatformConstants.DELETE_RESTORE_TRASH_PLATFORM, restoreOrRemovePlatformFromTrashList);
}
