export const initialConsoleState = () => ({
    fetchConsoleLoading: false,
    fetchConsoleFailure: false,
    fetchConsoleSuccess: false,
    consoleList: [],
    createConsoleLoading: false,
    createConsoleFailure: false,
    createConsoleSuccess: false,
    fetchConsoleByIDLoading: false,
    fetchConsoleByIDFailure: false,
    fetchConsoleByIDSuccess: false,
    consoleByID: {},
    editConsoleLoading: false,
    editConsoleFailure: false,
    editConsoleSuccess: false,
    deleteConsoleLoading: false,
    deleteConsoleFailure: false,
    deleteConsoleSuccess: false,
    consoleLogin: false,
    checkConsoleByIDLoading: false,
    checkConsoleByIDFailure: false,
    checkConsoleByIDSuccess: false,
    consoleCheckByID: {},
    consoleLoginLoading: false,
    consoleLoginFailure: false,
    consoleLoginSuccess: false,
    consoleResetLoading: false,
    consoleResetFailure: false,
    consoleResetSuccess: false,
});
